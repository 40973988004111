import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import Select from 'react-select';
import Carousel from 'react-elastic-carousel';
import customBase from '../../util/global.theme';

export const StyledCarousel = styled(Carousel)`
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    width: auto;
    display: none;
  }

  .card {
    flex: 0 0 auto;
  }
`;

export const StyledCarouselItem = styled.li`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      list-style: none;
      margin: -${theme?.size.spacer.xxs} 0 0 ${theme?.size.spacer.small};
      padding: 0;
      :first-child {
        margin-left: 0;
      }
      .active {
        background-color: ${theme?.color.primary.background};
        color: ${theme?.color.active.foreground};
      }
      .inactive {
        background-color: ${theme?.color.container.background};
        color: ${theme?.color.container.foreground};
      }
      .indicator {
        margin-top: 14px;
        margin-left: 110px;
        width: ${theme?.size.spacer.small};
        border-left: ${theme?.size.spacer.medium} solid transparent;
        border-right: ${theme?.size.spacer.medium} solid transparent;
        border-bottom: 20px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #e3e8ee;
        border-left-color: transparent;
        @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
          display: none;
        }
      }
    `;
  }}
`;

export const ButtonReset = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const StyledCarouselButton = styled(ButtonReset)`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: ${theme?.color.container.background};
      color: ${theme?.color.container.foreground};
      border-radius: ${theme.size.radius.medium};
      font-family: ${theme.font.primary.family};
      font-size: ${theme.font.primary.size.mediumTEMP};
      font-weight: ${theme.font.primary.weight.bold};
      text-decoration: none;
      text-transform: uppercase;
      width: 265px;
      `;
  }}
`;

export const StyledRightColumn = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      flex-direction: column;
      margin-top: -${theme?.size.spacer.xs};
      margin-right:  ${theme?.size.spacer.xs};
      @media (min-width: ${customBase.global.breakpoints.xsmall}px) and (max-width: ${customBase.global.breakpoints.medium}px) {
        padding-left: ${theme?.size.spacer.large};
      }
    `;
  }}
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: ${theme?.size.spacer.xs};
      margin-right: ${theme?.size.spacer.xs};
    `;
  }}
`;

export const IconParent = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.xs} ${theme?.size.spacer.small} ${theme?.size.spacer.xs};
    `;
  }}
`;

export const LeftAlign = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      text-align: left;
      padding-top: ${theme?.size.spacer.small};
      margin-left: ${theme?.size.spacer.xs};
    `;
  }}
`;

export const LeftAlignSmaller = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      text-align: left;
      margin-top: -${theme?.size.spacer.xs};
    `;
  }}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledIssuedOn = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      text-align: left;
      width: 140px;
      margin-top: -${theme?.size.radius.medium};
      margin-left: ${theme?.size.spacer.xs};
    `;
  }}
`;

export const AccountPolicyList = styled(Select)`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    width: 300px;
    @media screen and (min-width: ${
      customBase.global.breakpoints.medium + 1
    }px) {
      display: none;
    }
    .account-policy-list__dropdown-indicator {
      font-weight: ${theme.font.primary.weight.bold};
      color: ${theme?.color.secondary.background};
    }
    `;
  }}
`;

export const CarouselNavButton = styled.button`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.size.spacer.large};
      width: ${theme.size.spacer.large};
      background-color: ${theme?.color.container.background};
      border-style: solid;
      border-color: #e3e8ee;
      border-radius: 15%;
      border-width: ${theme.separator.line.thickness};
      font-family: ${theme.font.primary.family};
      font-size: ${theme.font.primary.size.large};
      font-weight: ${theme.font.primary.weight.bold};
      margin-top: 23px;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        display: none;
      `;
  }}
`;

export const InnerContent = styled.div`
  margin: auto;
  padding: auto;
`;

export const TriangleIndicator = styled.div``;
