import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';

export const Box = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        border-radius: ${theme?.size.radius.small};
        height: ${theme?.size.spacer.xl};
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
        `;
  }}
`;

export const Loading = styled.div`
  margin-left: 10%;
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        justify-content: center;
        text-align: center;
        border-radius: ${theme?.size.radius.small} 0px 0px ${theme?.size.radius.small};
        background-color: ${theme?.color.primary.background};
        width: 70px;
        height: 100%;
        margin-right: auto;
        padding-top: ${theme?.size.spacer.medium};
        `;
  }}
`;

export const ButtonHolder = styled.div`
  margin-right: auto;
  & > button {
    color: #3b3b3b;
  }
  & > button:hover {
    background-color: transparent;
  }
`;

export const DownloadIconHolder = styled.div`
  justify-content: center;
  width: 50px;
  height: 100%;
  margin-top: 25px;
  margin-left: auto;
`;
