import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const Box = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        border-radius: ${theme?.size.radius.small};
        height: ${theme?.size.spacer.xl};
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
        border-radius: ${theme?.size.radius.small};
        background-color: ${theme?.color.light.background};
        box-shadow: 0px ${theme?.size.spacer.xxs} 
        ${theme?.size.radius.small} rgba(0, 0, 0, 0.2);
        width: 100%;
        @media screen and (max-width: ${breakPoint}px) {
          margin-bottom: 25px;        
        }        
        @media screen and (min-width: ${breakPoint + 1}px) {  
          &:nth-child(even) {
            margin-top: ${theme.size.spacer.medium};
            margin-bottom: ${theme.size.spacer.medium}; 
          }   
        }  
        `;
  }}
`;

export const Loading = styled.div`
  margin-left: 10%;
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        justify-content: center;
        text-align: center;
        border-radius: ${theme?.size.radius.small} 0px 0px 
        ${theme?.size.radius.small};
        background-color: ${theme?.color.primary.background};
        width: 70px;
        height: 100%;        
        padding-top: 18px;
        @media screen and (min-width: ${breakPoint + 1}px) {
          margin-right: ${theme?.size.spacer.large};
        }
        @media screen and (max-width: ${breakPoint}px) {
          margin-right: ${theme?.size.spacer.medium};
        }
        & > svg {
          width: 24px;
          height: 24px;
        }
        `;
  }}
`;

export const ButtonHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin-right: auto;    
    @media screen and (min-width: ${breakPoint + 1}px) {
      margin-left: ${theme?.size.spacer.xs};
    }
    @media screen and (max-width: ${breakPoint}px) {
      margin-left: 0px;
    }
    & > button {
      color: #3b3b3b;
    }
    & > button:hover {
      background-color: transparent;      
    }
  `;
  }};
`;

export const LoadingContent = styled.div`
  margin-left: 10%;
`;
