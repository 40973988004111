import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_POLICY_SNAPSHOTS,
  GET_POLICY_SNAPSHOTSVariables,
} from '../../generated/GET_POLICY_SNAPSHOTS';
import { formatTitleCase } from '../../util/formatters';
import { useFeatureFlag } from '../../util/hooks';
import useDocumentDownload from '../../util/useDocumentDownload';
import SnapshotTable, {
  SnapshotInformation,
  RowExpandActions,
} from './SnapshotTable';

const getPolicySnapshotsQuery = loader(
  '../../graphql/queries/Get_Policy_Snapshots.graphql',
);

interface PolicySnapshotDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  policyNumber: string;
}

export const fetchPolicySnapshots = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  policyNumber: string,
): Promise<ApolloQueryResult<GET_POLICY_SNAPSHOTS>> => {
  const variables: GET_POLICY_SNAPSHOTSVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
  } as GET_POLICY_SNAPSHOTSVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_POLICY_SNAPSHOTS>
  > = awsAppSyncClient.query({
    query: getPolicySnapshotsQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICY_SNAPSHOTS>>;
  return queryResult;
};

export const processPolicySnapshotInfo = (
  data: GET_POLICY_SNAPSHOTS,
): SnapshotInformation => {
  let policyKey: string[] | null[] = ['-'];
  let activityType: string[] | null[] = ['-'];
  let description: string[] | null | never[] = ['-'];
  let effectiveDate: string[] | number[] | Date[] | null | undefined = ['-'];
  let issueDate: string[] | number[] | Date[] | null | undefined = ['-'];
  let amountTotal: string[] | number[] | undefined | null = ['-'];
  let amountChange: string[] | number[] | undefined | null = ['-'];
  let transactionNumber: string[] | undefined | null = ['-'];
  const sourceSystemCode: string =
    data?.account?.items?.[0]?.source_system_code;

  const snapshotInfoAccountItems =
    data?.account?.items[0]?.policy?.snapshots?.items;

  const snapshotInfoPolicyKey: string[] = [];
  const snapshotActivityType: string[] = [];
  const snapshotDescription: string[] | null | any = [];
  const snapshotEffectiveDate: string[] = [];
  const snapshotIssueDate: string[] = [];
  const snapshotAmountTotal: number[] = [];
  const snapshotAmountChange: number[] = [];
  const snapshotTransactionNumber: string[] | null = [];

  for (let i = 0; i < snapshotInfoAccountItems.length; i += 1) {
    if (snapshotInfoAccountItems[i]) {
      snapshotInfoPolicyKey.push(snapshotInfoAccountItems[i].policy_key);
      snapshotActivityType.push(snapshotInfoAccountItems[i].activity_type);
      snapshotEffectiveDate.push(snapshotInfoAccountItems[i].effective_date);
      snapshotIssueDate.push(snapshotInfoAccountItems[i].issue_date);
      snapshotAmountTotal.push(snapshotInfoAccountItems[i].amount_total);
      snapshotAmountChange.push(snapshotInfoAccountItems[i].amount_change);
      snapshotTransactionNumber.push(
        snapshotInfoAccountItems[i].transaction_number,
      );

      if (
        snapshotInfoAccountItems[i].job_type_status &&
        snapshotInfoAccountItems[i].job_type_status!.toLowerCase() ===
          'submission'
      ) {
        snapshotDescription.push(formatTitleCase('New Policy'));
      } else {
        snapshotDescription.push(
          formatTitleCase(snapshotInfoAccountItems[i].job_type_status),
        );
      }
    }
  }

  policyKey = snapshotInfoPolicyKey;
  activityType = snapshotActivityType;
  description = snapshotDescription;
  effectiveDate = snapshotEffectiveDate;
  issueDate = snapshotIssueDate;
  amountTotal = snapshotAmountTotal;
  amountChange = snapshotAmountChange;
  transactionNumber = snapshotTransactionNumber;

  return {
    policyKey,
    activityType,
    description,
    effectiveDate,
    issueDate,
    amountTotal,
    amountChange,
    transactionNumber,
    sourceSystemCode,
  };
};

const PolicySnapshotDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  policyNumber,
}: PolicySnapshotDataContainerProps) => {
  const [snapshotInfo, setSnapshotInfo] = useState<SnapshotInformation>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [rowExpanded, setRowExpanded] = useState<boolean[]>([]);

  const { flagDetails: snapshotInvoiceFlagDetails } = useFeatureFlag(
    'SnapshotInvoice',
  );

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    awsAppSyncClient,
    accountNumber,
    'snapshot',
  );

  const setOrUpdateIsRowExpandedFlags = (
    snapshotDetails: SnapshotInformation,
    action: string,
    rowExpandedData: boolean[],
  ) => {
    let rowExpandedFlags: boolean[] = [];
    if (snapshotDetails?.policyKey?.length) {
      if ([action]?.includes(RowExpandActions.SET))
        rowExpandedFlags = Array.from(snapshotDetails?.policyKey, () => false);
      else if ([action]?.includes(RowExpandActions.UPDATE))
        rowExpandedFlags = rowExpandedData?.length ? [...rowExpandedData] : [];
      setRowExpanded(rowExpandedFlags);
    }
  };

  useEffect(() => {
    fetchPolicySnapshots(awsAppSyncClient, accountNumber, policyNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_POLICY_SNAPSHOTS>) => {
        const processedSnapshotInfo = processPolicySnapshotInfo(
          apolloQueryResult.data,
        );
        setSnapshotInfo(processedSnapshotInfo);
        setOrUpdateIsRowExpandedFlags(
          processedSnapshotInfo,
          RowExpandActions.SET,
          rowExpanded,
        );
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('GET_POLICY_SNAPSHOTS ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [policyNumber]);

  const displayInvoiceItemsContent =
    snapshotInvoiceFlagDetails?.enabled &&
    ['GWPL'].includes(snapshotInfo?.sourceSystemCode as string);

  return (
    <>
      {documentsModal}
      <SnapshotTable
        awsAppSyncClient={awsAppSyncClient}
        policyNumber={policyNumber}
        accountNumber={accountNumber}
        snapshotInfo={snapshotInfo}
        onDocumentClick={onDocumentClick}
        error={error}
        loading={loading}
        displayInvoiceItemsContent={displayInvoiceItemsContent}
        rowExpanded={rowExpanded}
        setOrUpdateIsRowExpandedFlags={setOrUpdateIsRowExpandedFlags}
      />
    </>
  );
};

export default PolicySnapshotDataContainer;
