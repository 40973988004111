import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { SAIcon, SAIcons, SAIconSize, SAText } from '@saux/design-system-react';
import { formatDate, formatTitleCase } from '../../util/formatters';
import {
  Box,
  DocumentsHolder,
  IconHolder,
  ContentHolder,
  FirstHalf,
  DocumentName,
  Document,
  SecondHalf,
  ViewAll,
  ViewAllWrap,
  ViewAllText,
} from './PolicyDocumentsCollectionStyles';

export interface PolicyDocumentsCollectionProps {
  awsAppSyncClient: AWSAppSyncClient<any> | any;
  policyDocs: DocumentArray[];
  shouldShowViewAll: boolean;
  accountNumber: string;
  onDocumentClick?: Function;
  loading?: boolean;
  error?: Error;
}

export interface DocumentArray {
  fileType: string | undefined;
  fileName: string | undefined;
  createTime: string | undefined;
  docKey: string | undefined;
}

const PolicyDocumentsCollection = ({
  policyDocs,
  onDocumentClick,
  shouldShowViewAll,
  loading,
  error,
}: PolicyDocumentsCollectionProps) => {
  if (loading) {
    return (
      <Box>
        <SAText type="standard" text="Loading..." />
      </Box>
    );
  }
  if (error) {
    return (
      <Box>
        <SAText type="standard" text="Error" />
      </Box>
    );
  }
  /* eslint-disable */
  return (
    <>
      <DocumentsHolder>
        {policyDocs.map((doc) => {
          const name: string = doc?.fileName ?? 'Policy Document';
          const adjustedName = name
            .replace(/^\d{10}\s{1}/g, '')
            .replace(/\sEM_\d\d_\d\d\d\d\s/g, ' ');
          const displayedName =
            adjustedName.length > 26
              ? `${formatTitleCase(adjustedName.substring(0, 24))}...`
              : `${formatTitleCase(adjustedName)}`;
          if (doc && doc?.fileName) {
            return (
              <Box
                key={`${doc.createTime}`}
                className="policy-document-collection"
                onClick={() => {
                  if (onDocumentClick) {
                    onDocumentClick({
                      document_key: doc?.docKey,
                    });
                  }
                }}
              >
                <Document>
                  <IconHolder>
                    <SAIcon
                      icon={SAIcons.documentPDF}
                      size={SAIconSize.medium}
                    />
                  </IconHolder>
                  <ContentHolder>
                    <FirstHalf>
                      <DocumentName>
                        <SAText
                          colorVariant="primary"
                          type="standard"
                          text={displayedName}
                          weight="bold"
                        />
                      </DocumentName>
                    </FirstHalf>
                    <SecondHalf>
                      <SAText
                        colorVariant="default"
                        type="standard"
                        text={`${formatDate(doc?.createTime)}`}
                      />
                    </SecondHalf>
                  </ContentHolder>
                </Document>
              </Box>
            );
          }
        })}
      </DocumentsHolder>
      {shouldShowViewAll ? (
        <ViewAllWrap>
          <ViewAll onClick={() => window.open('/documents', '_self')}>
            <ViewAllText>VIEW ALL</ViewAllText>
            <SAIcon icon={SAIcons.chevronRight} size={SAIconSize.small} />
          </ViewAll>
        </ViewAllWrap>
      ) : (
        <div />
      )}
    </>
  );
};

PolicyDocumentsCollection.defaultProps = {
  loading: true,
  error: undefined,
  policyInfo: {},
};

export default PolicyDocumentsCollection;
