/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { formatTitleCase } from '../../util/formatters';
import PolicyAssetsChange from './PolicyAssetsChange';

export interface PolicyAssetsChangeDataContainerProps {
  loading?: boolean;
  error?: Error;
  assetCoverageInfo: any[];
  assets: any;
  policyType: string;
}

export const processIndividualAssetCoverages = (data: any, assets: any) => {
  let removedDuplicateAssetsFromInitialData: any[] = [];
  const assembledAssetCoverages: any[][] = [];
  let filteredData: any;

  removedDuplicateAssetsFromInitialData = data.filter(
    (coverage: any, index: any) => {
      const individualCoverage = coverage.asset_name;
      return (
        index ===
        data.findIndex((instance: any) => {
          return instance.asset_name === individualCoverage;
        })
      );
    },
  );
  removedDuplicateAssetsFromInitialData = removedDuplicateAssetsFromInitialData.map(
    (asset) => asset.asset,
  );
  for (let i = 0; i < removedDuplicateAssetsFromInitialData.length; i += 1) {
    filteredData = data.filter((entry: any) => {
      return entry.asset === removedDuplicateAssetsFromInitialData[i];
    });
    assembledAssetCoverages.push(filteredData);
  }

  return assembledAssetCoverages;
};

const PolicyDetailsAssetsDataContainer = ({
  assetCoverageInfo,
  assets,
  policyType,
}: PolicyAssetsChangeDataContainerProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [
    streamlinedAssetCoveragesInfo,
    setStreamlinedAssetCoveragesInfo,
  ] = useState<any>(assetCoverageInfo);

  const options: Function = () => {
    if (policyType.toLowerCase() === 'auto') {
      const foundCoverage = assetCoverageInfo.map((coverage: any) => {
        return {
          value: coverage?.asset_name,
          label: `${formatTitleCase(coverage?.asset_name)}`,
        };
      });
      return foundCoverage.reduce((uniqueCoverages: any[], coverage: any) => {
        if (
          JSON.stringify(uniqueCoverages).indexOf(JSON.stringify(coverage)) ===
          -1
        ) {
          uniqueCoverages.push(coverage);
        }
        return uniqueCoverages;
      }, []);
    }
    const foundCoverage = assetCoverageInfo.map((coverage: any) => {
      return {
        value: coverage?.asset,
        label: `${
          policyType?.toLowerCase() === 'umbrella'
            ? coverage?.asset
            : formatTitleCase(coverage?.asset)
        }`,
      };
    });
    return foundCoverage.reduce((uniqueCoverages: any[], coverage: any) => {
      if (
        JSON.stringify(uniqueCoverages).indexOf(JSON.stringify(coverage)) === -1
      ) {
        uniqueCoverages.push(coverage);
      }
      return uniqueCoverages;
    }, []);
  };

  useEffect(() => {
    if (assetCoverageInfo && assetCoverageInfo.length) {
      setStreamlinedAssetCoveragesInfo(
        processIndividualAssetCoverages(assetCoverageInfo, assets),
      );
      setLoading(false);
    }
  }, [assetCoverageInfo]);

  return (
    <PolicyAssetsChange
      policyType={policyType}
      assetCoverageInfo={streamlinedAssetCoveragesInfo}
      assets={assets}
      options={options}
      loading={loading}
    />
  );
};

export default PolicyDetailsAssetsDataContainer;
