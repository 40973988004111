import React from 'react';
import { SATable, SAText } from '@saux/design-system-react';
import {
  InvoiceItemsWrapper,
  ChangeBreakdownLabel,
  InvoiceDetailsTable,
  ListIconComp,
  ErrorWrapper,
  LoadingWrapper,
} from '../SnapshotTableStyles';

export const invoiceItemsTableColumns = [
  'Invoice Date',
  {
    name: 'Amount',
    process: (value: string) => (
      <SAText colorVariant={value.indexOf('-') === 0 ? 'alert' : 'default'}>
        {value}
      </SAText>
    ),
  },
];
export interface CurrentSnapshotDetails {
  transactionNumber: string;
  accountChange: number;
}
interface SnapshotInvoiceItemsProps {
  loading?: boolean;
  error?: Error;
  invoiceItems: (string | number | Date)[][] | any[][];
  currentSnapshotDetails: CurrentSnapshotDetails;
}

const SnapshotInvoiceItemsContent = ({
  loading,
  error,
  invoiceItems,
  currentSnapshotDetails,
}: SnapshotInvoiceItemsProps) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <SAText type="standard" text="Loading..." />
      </LoadingWrapper>
    );
  }
  if (error) {
    return (
      <ErrorWrapper>
        <SAText type="standard" text="Error" />
      </ErrorWrapper>
    );
  }
  if (!invoiceItems?.length) {
    return (
      <ErrorWrapper>
        <SAText
          type="standard"
          text={
            currentSnapshotDetails?.accountChange === 0
              ? 'This change did not affect your premium.'
              : 'No data found'
          }
        />
      </ErrorWrapper>
    );
  }

  return (
    <>
      <InvoiceItemsWrapper>
        <ChangeBreakdownLabel>
          <ListIconComp />
          <SAText type="standard" text="Change Breakdown" />
        </ChangeBreakdownLabel>
        <InvoiceDetailsTable
          className={`${invoiceItems?.length > 12 && 'display-scroll'}`}
        >
          <SATable
            columns={invoiceItemsTableColumns}
            data={invoiceItems}
            variant="table-to-listview"
          />
        </InvoiceDetailsTable>
      </InvoiceItemsWrapper>
    </>
  );
};

SnapshotInvoiceItemsContent.defaultProps = {
  loading: false,
  error: undefined,
};

export default SnapshotInvoiceItemsContent;
