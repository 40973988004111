import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import { RedirectCustomersToAgent } from '.';
import AgentAssistanceModal, { AgencyInfo } from './agentAssistanceModal';
import {
  GET_AGENCY_INFO,
  GET_AGENCY_INFOVariables,
} from '../../generated/GET_AGENCY_INFO';

const getAgencyInfoQuery = loader(
  '../../graphql/queries/Get_Agency_Info.graphql',
);

interface RedirectCustomersToAgentContainerProps {
  accountNumber: string | undefined;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export const platformServiceDetails = {
  name: 'State Auto Insurance Services',
  phoneNumber: '8663647489',
  email: 'saservice@stateauto.com',
};

export const fetchAgencyInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_AGENCY_INFO>> => {
  const variables: GET_AGENCY_INFOVariables = {
    account_number: accountNumber,
  } as GET_AGENCY_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_AGENCY_INFO>
  > = awsAppSyncClient.query({
    query: getAgencyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_AGENCY_INFO>>;
  return queryResult;
};

export const isEmpty = (obj: any) => {
  return Object?.values(obj)?.every((x) => x === null || x === '-');
};

export const processAgencyInfo = (data: GET_AGENCY_INFO) => {
  let agencyName: string | null | undefined = '-';
  let businessPhone: string | null | undefined = '-';
  let businessEmail: string | null | undefined = '-';

  const agencyInfoAccountItems = data?.account?.items;

  if (
    Array.isArray(agencyInfoAccountItems) &&
    agencyInfoAccountItems?.[0]?.agencyContactInfo
  ) {
    const queriedAgencyName =
      agencyInfoAccountItems[0]?.agencyContactInfo?.agency_name;
    const queriedBusinessPhone =
      agencyInfoAccountItems[0]?.agencyContactInfo?.business_phone;
    const queriedBusinessEmail =
      agencyInfoAccountItems[0]?.agencyContactInfo?.business_email;

    if (agencyInfoAccountItems[0]?.service_tier?.toLowerCase() === 'platform') {
      agencyName = platformServiceDetails.name;
      businessPhone = platformServiceDetails.phoneNumber;
      businessEmail = platformServiceDetails.email;
    } else {
      if (queriedAgencyName) {
        agencyName = queriedAgencyName;
      } else agencyName = '-';

      if (queriedBusinessPhone) {
        businessPhone = queriedBusinessPhone;
      } else businessPhone = '-';

      if (queriedBusinessEmail) {
        businessEmail = queriedBusinessEmail;
      } else businessEmail = '-';
    }
  }
  return {
    name: agencyName,
    phoneNumber: businessPhone,
    email: businessEmail,
  };
};

const RedirectCustomersToAgentContainer = ({
  accountNumber,
  awsAppSyncClient,
}: RedirectCustomersToAgentContainerProps) => {
  const [showAgentHelpModal, setShowAgentHelpModal] = useState<boolean>(false);
  const [selectedCustomerAction, setSelectedCustomerAction] = useState<string>(
    '',
  );
  const [agencyInfo, setAgencyInfo] = useState<AgencyInfo | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (accountNumber) {
      fetchAgencyInfo(awsAppSyncClient, accountNumber)
        .then((result: ApolloQueryResult<GET_AGENCY_INFO>) => {
          const processedAgencyInfo = processAgencyInfo(result?.data);
          if (!isEmpty(processedAgencyInfo)) {
            setAgencyInfo(processedAgencyInfo);
          } else setError(new Error('Empty Agency Values'));
          setLoading(false);
        })
        .catch((err: Error) => {
          console.error('GET_AGENCY_INFO ERROR: ', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [accountNumber]);

  return (
    <>
      <RedirectCustomersToAgent
        openAgentAssistanceModal={(action: string) => {
          setShowAgentHelpModal(true);
          setSelectedCustomerAction(action);
        }}
        error={error}
        loading={loading}
      />
      {showAgentHelpModal && (
        <AgentAssistanceModal
          selectedCustomerAction={selectedCustomerAction}
          agencyInfo={agencyInfo}
          error={error}
          loading={loading}
          closeModal={() => {
            setShowAgentHelpModal(false);
          }}
        />
      )}
    </>
  );
};

export default RedirectCustomersToAgentContainer;
