const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

export const formatPremiumChangeMoney = (amount: number | string): string => {
  const numAmount = Number(amount.toString().replace(/[$,]/g, ''));

  if (Number.isNaN(numAmount)) {
    return '-';
  }

  if (numAmount > 0) {
    return `+${formatter.format(numAmount)}`;
  }

  if (numAmount === 0) {
    return '-';
  }
  return formatter.format(numAmount);
};

export default (
  amount: number | string | undefined | null,
  defaultReturn?: string,
) => {
  if (!amount && !defaultReturn) return null;

  if (!amount && defaultReturn) return defaultReturn;

  if (amount === 'PAID IN FULL') {
    return '$0.00';
  }

  // Strip out leading dollar symbol and commas and cast to Number.
  let numAmount = Number(amount?.toString().replace(/[$,]/g, ''));

  if (Number.isNaN(numAmount)) {
    return null;
  }

  // This treats the -0 edge case, which seems to be a valid number in JS
  if (numAmount === 0) {
    numAmount = Math.abs(numAmount);
  }

  return numAmount < 0
    ? `(${formatter.format(Math.abs(numAmount))})`
    : formatter.format(numAmount);
};
