import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import {
  GET_DOCUMENTS_BY_ACCOUNT,
  GET_DOCUMENTS_BY_ACCOUNTVariables,
} from '../../generated/GET_DOCUMENTS_BY_ACCOUNT';
import {
  GET_POLICY_INFO,
  GET_POLICY_INFOVariables,
} from '../../generated/GET_POLICY_INFO';
import { DownloadDocsButton } from '.';
import { formatTitleCase } from '../../util/formatters';
import { PolicyInformation } from './DownloadDocsButton';
import useDocumentDownload from '../../util/useDocumentDownload';
import { PolicyType } from '../policyCarousel/PolicyCarousel';

const getPolicyDocuments = loader(
  '../../graphql/queries/Get_Documents_By_Account.graphql',
);
const getPolicyInfoQuery = loader(
  '../../graphql/queries/Get_Policy_Info.graphql',
);

interface DownloadDocumentsDataContainerProps {
  accountNumber: string;
  policyNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export const fetchPolicyInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  policyNumber: string,
): Promise<ApolloQueryResult<GET_POLICY_INFO>> => {
  const variables: GET_POLICY_INFOVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
  } as GET_POLICY_INFOVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_POLICY_INFO>
  > = awsAppSyncClient.query({
    query: getPolicyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICY_INFO>>;
  return queryResult;
};

export const fetchPolicyDocuments = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>> => {
  const variables: GET_DOCUMENTS_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_DOCUMENTS_BY_ACCOUNTVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>
  > = awsAppSyncClient.query({
    query: getPolicyDocuments,
    variables,
  }) as Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>>;
  return queryResult;
};

export const checkCurrentPolicyTraits = (
  data: GET_POLICY_INFO,
): PolicyInformation => {
  let policyType: string | null = '-';
  let policyNumber: string | null | undefined = '-';

  const policyInfoAccountItems = data?.account?.items;
  if (policyInfoAccountItems && policyInfoAccountItems.length > 0) {
    const policyInfoAccountItemsPolicy = policyInfoAccountItems[0]?.policy;
    let type: string = '';

    switch (policyInfoAccountItemsPolicy?.policy_type) {
      case PolicyType.HOME:
        if (policyInfoAccountItemsPolicy?.form === 'DP3')
          type = 'Dwelling Fire';
        else if (policyInfoAccountItemsPolicy?.form === 'HO4') type = 'Renters';
        else if (policyInfoAccountItemsPolicy?.form === 'HO6') type = 'Condo';
        else type = PolicyType.HOME;
        break;
      case PolicyType.AUTO:
        type = PolicyType.AUTO;
        break;
      case PolicyType.COMMERCIAL_AUTO:
        type = PolicyType.COMMERCIAL_AUTO;
        break;
      case PolicyType.COMMERCIAL_UMBRELLA:
        type = PolicyType.COMMERCIAL_UMBRELLA;
        break;
      case PolicyType.BUSINESS_OWNERS:
        type = PolicyType.BUSINESS_OWNERS;
        break;
      case PolicyType.FARM_AND_RANCH:
        type = PolicyType.FARM_AND_RANCH;
        break;
      case PolicyType.FARM_OWNERS_UMBRELLA:
        type = PolicyType.FARM_OWNERS_UMBRELLA;
        break;
      case PolicyType.FARM_OWNERS_AUTO:
        type = PolicyType.FARM_OWNERS_AUTO;
        break;
      case PolicyType.WORKERS_COMP:
        type = PolicyType.WORKERS_COMP;
        break;
      case PolicyType.COMMERCIAL_PACKAGE:
        type = PolicyType.COMMERCIAL_PACKAGE;
        break;
      case PolicyType.UMBRELLA:
        type = PolicyType.UMBRELLA;
        break;
      default:
        type = PolicyType.UNKNOWN;
        break;
    }

    const policynumber = policyInfoAccountItemsPolicy?.policy_number;

    policyType = formatTitleCase(type);
    policyNumber = policynumber;
  }
  return {
    policyType,
    policyNumber,
  };
};

export const processPolicyDoc = (
  data: GET_DOCUMENTS_BY_ACCOUNT,
  policyType: string,
  policyNum: string,
) => {
  let documentskey: string = '-';

  let conditions = ['Policy', 'Declaration'];
  switch (policyType) {
    case 'Auto':
      conditions = ['Auto', 'ID'];
      break;
    case 'Home':
      conditions[0] = 'Home';
      break;
    case 'Umbrella':
      conditions[0] = 'Umbrella';
      break;
    case 'Dwelling Fire':
      conditions[0] = 'Dwelling Fire';
      break;
    case 'Commercial Package':
      conditions[0] = 'Package';
      break;
    default:
      break;
  }
  const documentData = data.account?.items;
  if (
    documentData &&
    documentData.length > 0 &&
    documentData[0]?.account_documents
  ) {
    const documentDataContent = documentData[0]?.account_documents?.items;
    if (documentDataContent) {
      for (let i = 0; i < documentDataContent.length; i += 1) {
        if (
          formatTitleCase(documentDataContent[i]?.classification) ===
            'Policy' &&
          documentDataContent[i]?.policy_number?.toString() === policyNum &&
          documentDataContent[i]?.file_name?.includes(conditions[0]) &&
          documentDataContent[i]?.file_name?.includes(conditions[1])
        ) {
          documentskey = documentDataContent[i]?.documents_key ?? '';
          break;
        }
      }
    }
  }
  return {
    documentskey,
  };
};

const DownloadDocsDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  policyNumber,
}: DownloadDocumentsDataContainerProps) => {
  const [policyInfo, setPolicyInfo] = useState<any>(undefined);
  const [policyType, setPolicyType] = useState<any>(undefined);
  const [policyDoc, setPolicyDoc] = useState<any>(undefined);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    awsAppSyncClient,
    accountNumber,
    'policy',
  );

  useEffect(() => {
    fetchPolicyInfo(awsAppSyncClient, accountNumber, policyNumber)
      .then((apolloQueryResult: ApolloQueryResult<any>) => {
        const processedPolicyInfo = checkCurrentPolicyTraits(
          apolloQueryResult.data,
        );
        setPolicyType(processedPolicyInfo.policyType);
        setPolicyInfo(processedPolicyInfo.policyNumber);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.log('Fetch Policy Info Hook Error: ', err);
        setError(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (policyInfo !== undefined && policyType !== undefined) {
      fetchPolicyDocuments(awsAppSyncClient, accountNumber)
        .then((apolloQueryResult: ApolloQueryResult<any>) => {
          const processedPolicyDoc = processPolicyDoc(
            apolloQueryResult.data,
            policyType as string,
            policyNumber as string,
          );
          setPolicyDoc(processedPolicyDoc.documentskey);
          setLoading(false);
        })
        .catch((err: Error) => {
          console.log('Fetch Policy Documents (All Docs) Hook Error: ', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [policyInfo]);

  return (
    <>
      {documentsModal}
      <DownloadDocsButton
        awsAppSyncClient={awsAppSyncClient}
        accountNumber={accountNumber}
        policyInfo={policyInfo}
        policyDoc={policyDoc}
        onDocumentClick={onDocumentClick}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default DownloadDocsDataContainer;
