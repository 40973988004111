import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

const plusOnePx = customBase.global.breakpoints.xsmall + 1;

export const VehicleItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid #d9dfeb;
  }
`;

export const ExcludedSpacing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  width: 280px;
  gap: 20px;
`;

export const NameText = styled.div`
  color: hsl(0, 0%, 23%);
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  text-transform: none;
`;

export const RelationSpacer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const StyledLabel = styled.span`
  font-weight: bold;
`;

export const VehicleInfoLabel = styled(StyledLabel)`
  font-weight: bold;
  font-size: 0.75rem;
  min-width: 3.5rem;
`;

export const ContentSize = styled.div`
  height: 20px;
  width: 80px;
  margin-bottom: 10px;
`;

export const ContentsTag = styled.div`
  border: 1px solid gray;
  font-size: 12px;
  font-weight: 700;
  display: inline;
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  margin: 0px 10px 10px 0px;
`;

export const Box = styled.div`
  margin: 0;
  padding: 0;
  max-width: 625px;
`;

export const InnerBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin: ${theme.size.spacer.large} ${theme.size.spacer.xs} ${theme.size.spacer.large} ${theme.size.spacer.xs};
    `;
  }}
`;

export const Spacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.separator.line.thickness};
      background-color: ${theme.color.background.background};
      margin-top: ${theme.size.spacer.small};
      margin-bottom: 20px;
    `;
  }}
`;

export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SimpleColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${customBase.global.breakpoints.large}px) {
    flex-wrap: wrap;
  }
  @media (min-width: ${plusOnePx}px) and (max-width: 2400px) {
    display: flex;
    flex-direction: row;
  }
`;

export const RowCenterAligned = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${customBase.global.breakpoints.large}px) {
    flex-wrap: wrap;
  }
  @media (min-width: ${plusOnePx}px) and (max-width: 2400px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const InnerBoxContent = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin: ${theme.size.spacer.medium} auto;
    @media (min-width: ${customBase.global.breakpoints.medium}px) {
      margin: 12px auto;
    }
`;
  }}
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentsTagHolder = styled.div`
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .xsmall}px) {
    margin-right: auto;
    padding-top: 7px;
    padding-bottom: 3px;
  }
  @media (min-width: ${plusOnePx}px) and (max-width: 2400px) {
    margin-left: auto;
  }
`;

export const SecondNestedColumn = styled.div`
  width: 60%;
  max-width: 300px;
  min-width: 175px;
`;

export const SpecialSecondNestedColumn = styled.div`
  width: 60%;
  max-width: 300px;
  min-width: 150px;
  margin-left: 20px;
  @media (max-width: ${customBase.global.breakpoints.large}px) {
    margin-left: 0;
  }
`;

export const SecondNestedRow = styled.div`
  width: auto;
`;

export const SummaryGrayBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: ${theme.color.background.background};
      width: 100%;
      min-width: 275px;
      height: 75px;
      border-radius: ${theme.size.spacer.xs};
      @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.large}px) {
        margin-top: 25px;
      }
    `;
  }}
`;

export const SummaryGrayBoxInnerContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SmallSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.size.spacer.xs};
      width: 100%;
    `;
  }}
`;

export const ExtraSmallSpacer = styled.div`
  height: 0.5px;
  width: 100%;
`;

export const AltSmallSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.size.spacer.small};
      width: 100%;
    `;
  }}
`;

export const OtherInsuredSmallSpacer = styled.div`
  height: 7px;
  width: 100%;
`;

export const BigSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: ${theme.size.spacer.medium};
      width: 100%;
    `;
  }}
`;

export const StyledAssetsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AssetIconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-left: ${theme.size.spacer.medium};
      margin-top: ${theme.size.spacer.small};
    `;
  }}
`;

export const AssetNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-left: ${theme.size.spacer.medium};
      margin-top: ${theme.size.spacer.small};
    `;
  }}
`;

export const AltAssetNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-left: ${theme.size.spacer.medium};
      padding-right: 20px;
    `;
  }}
`;

export const AssetHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-left: 19px;
      margin-top: -${theme.size.spacer.xxs};
      margin-bottom: ${theme.size.spacer.small};
      `;
  }}
`;

export const AssetValueHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-left: ${theme.size.spacer.medium};
      margin-top: -${theme.size.spacer.xxs};
      margin-bottom: ${theme.size.spacer.small};
      `;
  }}
`;

export const Asset = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      font-size: 0.75rem;
      letter-spacing: 0.075rem;
      font-weight: ${theme.font.primary.weight.normal};
    `;
  }}
`;

export const LineWrapAsset = styled.span`
  overflow-wrap: break-word;
`;

export const UmbrellaAddressAssetHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-left: 42px;
      margin-top: -${theme.size.spacer.medium};
    `;
  }}
`;

export const MultipleAssetHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: ${theme.color.background.background};
      border-radius: ${theme.size.radius.medium};
      font-family: ${theme.font.primary.family};
      font-size: ${theme.font.primary.size.mediumTEMP};
      font-weight: ${theme.font.primary.weight.bold};
      margin: 0px ${theme.size.spacer.medium} ${theme.size.spacer.large} ${theme.size.spacer.medium};
      text-decoration: none;
      text-transform: uppercase;
      height: 75px;
      width: 45%;

      @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.large}px) {
        margin: 0 0 ${theme.size.spacer.large} !important;
        width: 100%;
      }
      `;
  }}
`;

export const PaginationWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SingleAssetHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: #F4F6FA;
      border-radius: ${theme.size.radius.medium};
      font-family: ${theme.font.primary.family};
      font-size: ${theme.font.primary.size.mediumTEMP};
      font-weight: ${theme.font.primary.weight.bold};
      text-decoration: none;
      text-transform: uppercase;
      height: 75px;
      min-width: 300px;
      @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.large}px) {
        width: 100%;
        min-width: 270px;
      }
      `;
  }}
`;

export const VIN = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      font-size: 0.75rem;
      letter-spacing: 0.075rem;
      font-weight: ${theme.font.primary.weight.normal};
    `;
  }}
`;

export const VINHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-left: 24px;
      margin-top: -${theme.size.spacer.xs};
      margin-bottom: ${theme.size.spacer.small};
    `;
  }}
`;

export const VINValueHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-left: ${theme.size.spacer.medium};
      margin-top: -${theme.size.spacer.xs};
      margin-bottom: ${theme.size.spacer.small};
    `;
  }}
`;
