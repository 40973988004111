import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import {
  GET_DOCUMENTS_BY_ACCOUNT,
  GET_DOCUMENTS_BY_ACCOUNTVariables,
} from '../../generated/GET_DOCUMENTS_BY_ACCOUNT';
import { PolicyDocumentsCollection } from '.';
import { formatTitleCase } from '../../util/formatters';
import useDocumentDownload from '../../util/useDocumentDownload';

const getPolicyDocuments = loader(
  '../../graphql/queries/Get_Documents_By_Account.graphql',
);

interface DownloadDocumentsDataContainerProps {
  accountNumber: string;
  policyNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export const fetchPolicyDocuments = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>> => {
  const variables: GET_DOCUMENTS_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_DOCUMENTS_BY_ACCOUNTVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>
  > = awsAppSyncClient.query({
    query: getPolicyDocuments,
    variables,
  }) as Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>>;
  return queryResult;
};

export const processPolicyDocs = (
  data: GET_DOCUMENTS_BY_ACCOUNT,
  policyNum: string,
) => {
  const documents: object[] = [];

  const documentData = data.account?.items;
  if (
    documentData &&
    documentData.length > 0 &&
    documentData[0]?.account_documents
  ) {
    const documentDataContent = documentData[0]?.account_documents?.items;
    if (documentDataContent) {
      documentDataContent.forEach((doc) => {
        if (
          doc?.security_type === 'Unrestricted' &&
          formatTitleCase(doc?.classification) === 'Policy' &&
          doc?.policy_number?.toString() === policyNum &&
          doc?.file_type?.toLowerCase() !== 'text/html'
        ) {
          documents.push({
            docKey: doc.documents_key,
            fileName: doc.file_name,
            fileType: doc.file_type,
            createTime: doc.create_time,
          });
        }
      });
    }
  }
  return documents;
};

const PolicyDocsCollectionDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  policyNumber,
}: DownloadDocumentsDataContainerProps) => {
  const [policyDocs, setPolicyDocs] = useState<any[]>([]);
  const [policyDocsSortedByDate, setPolicyDocsSortedByDate] = useState<any[]>(
    [],
  );
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldShowViewAll, setShouldShowViewAll] = useState<boolean>(false);

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    awsAppSyncClient,
    accountNumber,
    'document',
  );

  useEffect(() => {
    if (policyNumber) {
      fetchPolicyDocuments(awsAppSyncClient, accountNumber)
        .then((apolloQueryResult: ApolloQueryResult<any>) => {
          const processedPolicyDocs = processPolicyDocs(
            apolloQueryResult.data,
            policyNumber as string,
          );
          if (processedPolicyDocs.length > 5) {
            processedPolicyDocs.length = 5;
            setShouldShowViewAll(true);
          }
          setPolicyDocs(processedPolicyDocs);
          setLoading(false);
        })
        .catch((err: Error) => {
          console.log('Fetch Policy Documents (All Docs) Hook Error: ', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [policyNumber]);

  useEffect(() => {
    if (policyDocs.length > 1) {
      setPolicyDocsSortedByDate(
        [...policyDocs].sort((a, b) => {
          return b.createTime.localeCompare(a.createTime);
        }),
      );
    }
  }, [policyDocs]);

  return (
    <>
      {documentsModal}
      <PolicyDocumentsCollection
        awsAppSyncClient={awsAppSyncClient}
        accountNumber={accountNumber}
        policyDocs={policyDocsSortedByDate}
        shouldShowViewAll={shouldShowViewAll}
        onDocumentClick={onDocumentClick}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default PolicyDocsCollectionDataContainer;
