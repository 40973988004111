import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../../util/global.theme';

const breakPoint = customBase?.global?.breakpoints?.medium;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `      
      width: 80%;
      top: 50%;
      left: 50%;      
      background-color: ${theme?.color?.primary?.foreground};
      border-radius: ${theme?.size?.radius?.small};
      text-align: center;
      position: absolute;      
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      @media screen and (min-width: ${breakPoint}px) {
        height: 400px;
        width: 600px;
        padding-top: 0;
      }
      @media screen and (max-width: ${breakPoint}px) {
        width: 100vw;
        min-width: 100%;    
        height: 100vh;
        min-height: 100%;
        padding-top: 8px;
      }
      `;
  }}
`;

export const ModalContents = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 20px 20px 25px;
    @media screen and (max-width: ${breakPoint}px) {
      width: 100%;      
      overflow: auto;
      padding: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.medium} ${theme?.size?.spacer?.medium} 20px;
    }
 `};
`;

export const ModalHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// font-size for title  best at 23px
export const ModalTitle = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-size: ${theme?.font?.primary?.size?.medium};
    font-weight: ${theme?.font?.primary?.weight?.bold};
    @media screen and (max-width: ${breakPoint}px) {
      transform: scale(1.05);
      margin-left: 2%;
    }
  `};
`;

export const ModalCloseWrap = styled.div`
  cursor: pointer;
  @media screen and (max-width: ${breakPoint}px) {
    transform: scale(0.9);
  }
`;

export const ModalBody = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.xs} ${theme?.size?.spacer?.medium} 0;
    @media screen and (max-width: ${breakPoint}px) {
      flex: 1 0 80%;
        margin: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.medium} ${theme?.size?.spacer?.medium} 0;
      }
`}
`;

export const CloseButton = styled.div`
  @media screen and (max-width: ${breakPoint}px) {
    & > button {
      width: 50vw;
    }
  }
`;

export const ClickableLink = styled.a`
  text-decoration: none;
  @media screen and (max-width: ${breakPoint}px) {
    & > p {
      transform: scale(1.04);
    }
  }
`;

export const InvoiceDetailsTable = styled.div`
  @media screen and (max-width: ${breakPoint}px) {
    & {
      width: 100%;
    }
  }
  &.display-scroll article {
    height: 100%;
    overflow-y: auto;
  }
  article {
    [data-testid='Box'] {
      & > section:hover {
        border-left: 4px solid transparent;
      }
      &:last-child > section {
        &,
        &:hover {
          background-color: #619424;
          border-radius: 0px;
          span {
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
          }
        }
        & > section {
          ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
              &:last-child {
                justify-content: flex-end;
              }
              & > span:first-child {
                display: none;
              }
              & > span:last-child {
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 1px;
  padding: 10px;
`;
export const ErrorWrapper = styled.div`
  margin: 1px;
  padding: 10px;
`;
