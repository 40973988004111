/* eslint-disable react/prop-types */
import React from 'react';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
  SATableColumn,
  SAText,
} from '@saux/design-system-react';
import {
  Backdrop,
  ErrorWrapper,
  InvoiceDetailsTable,
  LoadingWrapper,
  Modal,
  ModalBody,
  ModalCloseWrap,
  ModalContents,
  ModalHeader,
  ModalTitle,
} from './InvoiceBreakdownModalStyles';

export interface CurrentSnapshotDetails {
  transactionNumber: string;
  accountChange: number;
}

export interface ModalProps {
  closeModal: Function;
  loading?: boolean;
  error: Error | undefined;
  invoiceItems: (string | number | Date)[][] | any[][];
  currentSnapshotDetails: CurrentSnapshotDetails;
  columns: SATableColumn[];
}

export interface ModalHeaderProps {
  headerTitle: string | undefined;
  closeModal: Function;
}

const ModalHeaderContainer = ({
  headerTitle,
  closeModal,
}: ModalHeaderProps) => (
  <ModalHeader>
    <ModalTitle>
      <SAText type="heading-3" text={headerTitle} />
    </ModalTitle>
    <ModalCloseWrap
      onClick={() => {
        closeModal();
      }}
    >
      <SAIcon icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" />
    </ModalCloseWrap>
  </ModalHeader>
);

const ModalBodyContainer = ({
  invoiceItems,
  columns,
  currentSnapshotDetails,
}: ModalProps) => (
  <ModalBody>
    {!invoiceItems?.length ? (
      <ErrorWrapper>
        <SAText
          type="standard"
          text={
            currentSnapshotDetails?.accountChange === 0
              ? 'This change did not affect your premium.'
              : 'No data found'
          }
        />
      </ErrorWrapper>
    ) : (
      <InvoiceDetailsTable
        className={`${invoiceItems?.length > 12 && 'display-scroll'}`}
      >
        <SATable
          columns={columns}
          data={invoiceItems}
          variant="table-to-listview"
        />
      </InvoiceDetailsTable>
    )}
  </ModalBody>
);

const InvoiceBreakdownModal = ({
  closeModal,
  loading,
  error,
  invoiceItems,
  currentSnapshotDetails,
  columns,
}: ModalProps) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <SAText type="standard" text="Loading..." />
      </LoadingWrapper>
    );
  }
  if (error) {
    return (
      <ErrorWrapper>
        <SAText type="standard" text="Error" />
      </ErrorWrapper>
    );
  }

  return (
    <Backdrop>
      <Modal>
        <ModalContents>
          <ModalHeaderContainer
            headerTitle="Change Breakdown"
            closeModal={closeModal}
          />
          <ModalBodyContainer
            closeModal={closeModal}
            invoiceItems={invoiceItems}
            currentSnapshotDetails={currentSnapshotDetails}
            columns={columns}
            error={error}
          />
        </ModalContents>
      </Modal>
    </Backdrop>
  );
};

export default InvoiceBreakdownModal;
