import { GlobalStyle, SAUX360Theme } from '@saux/design-system-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Policy from './Policy';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    renderPolicy: Function;
    unmountCreatereactapp: Function;
  }
}
window.renderPolicy = (containerId: string, session: any) => {
  ReactDOM.render(
    <ThemeProvider theme={SAUX360Theme}>
      <GlobalStyle />
      <Policy session={session} />
    </ThemeProvider>,
    document.getElementById(containerId),
  );
  serviceWorker.unregister();
};

serviceWorker.unregister();
