import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../global.theme';

export const Box = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        border-radius: ${theme?.size.radius.small};
        height: ${theme?.size.spacer.xl};
        margin-left: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
        `;
  }}
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        justify-content: center;
        text-align: center;
        border-radius: ${theme?.size.radius.small} 0px 0px ${theme?.size.radius.small};
        background-color: ${theme?.color.primary.background};
        width: 70px;
        height: 100%;
        margin-right: auto;
        padding-top: ${theme?.size.spacer.medium};
        `;
  }}
`;

export const ButtonHolder = styled.div`
  margin-right: auto;
`;

export const LoadingHolder = styled.div`
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    margin-left: -20%;
  }
  margin-right: 5%;
`;

export const DownloadIconHolder = styled.div`
  justify-content: center;
  width: 50px;
  height: 100%;
  margin-top: 25px;
  margin-left: auto;
`;

export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          height: 400px;
          width: 600px;
          padding-top: 0px;
      }
      padding-top: 25px;
      width: 80%;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      `;
  }}
`;

export const Title = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          margin-left: ${theme?.size.spacer.large};
          margin-top: ${theme?.size.spacer.large};
          display: flex;
          flex-direction: row;
          align-items: center;
      }
      margin-left: ${theme?.size.spacer.small};
      `;
  }}
`;

export const Content = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
          margin-top: 25px;
          margin-left: ${theme.size.spacer.large};
          @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          margin-left: 108px;
          margin-top: ${theme.size.icon.small};
          }
      `;
  }}
`;

export const ErrorHolder = styled.div`
  height: 140px;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Close = styled.div`
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    margin-left: 425px;
    margin-top: -60px;
  }
  margin-left: 200px;
  margin-top: 80px;
  padding-bottom: 25px;
  font-size: 1.125rem;
  &:hover {
    cursor: pointer;
  }
`;

export const Spacer = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
  width: ${theme?.size.spacer.medium};
  `;
  }}
`;

export const StyledFooter = styled.div`
  align-items: flex-end;
  padding-top: 50px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    margin-top: 80px;
  }
`;
