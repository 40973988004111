import React, { ReactElement } from 'react';
import {
  SAIcon,
  SAText,
  SAIcons,
  SAIconSize,
  SAIconColorVariant,
} from '@saux/design-system-react';
import { RenderArrowProps } from 'react-elastic-carousel';
import { ReactComponent as RentersBlue } from '../../assets/images/RentersBlue.svg';
import { ReactComponent as RentersWhite } from '../../assets/images/RentersWhite.svg';
import { ReactComponent as DwellingFireWhite } from '../../assets/images/DwellingFireWhite.svg';
import { ReactComponent as DwellingFireBlue } from '../../assets/images/DwellingFireBlue.svg';
import { ReactComponent as CondoWhite } from '../../assets/images/CondoWhite.svg';
import { ReactComponent as CondoBlue } from '../../assets/images/CondoBlue.svg';
import {
  CarouselNavButton,
  StyledCarousel,
  StyledCarouselItem,
  StyledCarouselButton,
  StyledIssuedOn,
  StyledRightColumn,
  TriangleIndicator,
  AccountPolicyList,
  InnerContent,
  IconHolder,
  Column,
  Row,
  IconParent,
  LeftAlign,
  LeftAlignSmaller,
} from './PolicyCarouselStyles';
import { formatDate } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface PolicyCarouselProps {
  policies?: Policy[];
  policyNumber: string;
  loading?: boolean;
  error?: Error;
  setPolicyNumber: Function;
}

export enum PolicyType {
  HOME = 'Home',
  AUTO = 'Auto',
  UMBRELLA = 'Umbrella',
  UNKNOWN = 'Unknown',
  COMMERCIAL_AUTO = 'Commercial Auto',
  COMMERCIAL_UMBRELLA = 'Commercial Umbrella',
  FARM_AND_RANCH = 'Farm & Ranch',
  FARM_OWNERS_UMBRELLA = 'Farmowners Umbrella',
  FARM_OWNERS_AUTO = 'Farmowners Auto',
  WORKERS_COMP = 'Workers Comp',
  BUSINESS_OWNERS = 'Businessowners',
  COMMERCIAL_PACKAGE = 'Commercial Package',
}

export interface Policy {
  type: PolicyType | string | undefined;
  number: string | undefined;
  issuedOn: string | undefined;
  expiration: string | undefined;
  status: string | undefined;
}

export const matchedIconToPolicyType = (
  policy: Policy,
  index: number,
  selectedPolicyIndex: number,
): ReactElement | undefined => {
  switch (policy.type) {
    case PolicyType.AUTO:
    case PolicyType.COMMERCIAL_AUTO:
    case PolicyType.FARM_OWNERS_AUTO:
      return (
        <SAIcon
          icon={SAIcons.auto}
          size={SAIconSize.medium}
          colorVariant={
            index === selectedPolicyIndex
              ? ('light' as SAIconColorVariant)
              : ('primary' as SAIconColorVariant)
          }
        />
      );
    case PolicyType.HOME:
    case PolicyType.BUSINESS_OWNERS:
    case PolicyType.FARM_AND_RANCH:
      return (
        <SAIcon
          icon={SAIcons.home}
          size={SAIconSize.medium}
          colorVariant={
            index === selectedPolicyIndex
              ? ('light' as SAIconColorVariant)
              : ('primary' as SAIconColorVariant)
          }
        />
      );
    case PolicyType.UMBRELLA:
    case PolicyType.COMMERCIAL_UMBRELLA:
    case PolicyType.FARM_OWNERS_UMBRELLA:
    case PolicyType.WORKERS_COMP:
    case PolicyType.COMMERCIAL_PACKAGE:
      return (
        <SAIcon
          icon={SAIcons.umbrella}
          size={SAIconSize.medium}
          colorVariant={
            index === selectedPolicyIndex
              ? ('light' as SAIconColorVariant)
              : ('primary' as SAIconColorVariant)
          }
        />
      );
    case 'Dwelling Fire':
      if (index === selectedPolicyIndex) return <DwellingFireWhite />;
      return <DwellingFireBlue />;
    case 'Renters':
      if (index === selectedPolicyIndex) return <RentersWhite />;
      return <RentersBlue />;
    case 'Condo':
      if (index === selectedPolicyIndex) return <CondoWhite />;
      return <CondoBlue />;
    default:
      return undefined;
  }
};

function listOptions(policies: Policy[]): any[] {
  return policies.map((policy: Policy) => {
    const policyNumber: string =
      policy && policy.number ? policy.number : '00000000';
    const lastFour: string | undefined = policyNumber?.substring(
      policyNumber.length - 4,
    );
    return { value: policyNumber, label: `${policy.type}...${lastFour}` };
  });
}

function findSelectedPolicy(
  policies: Policy[],
  displayedPolicy: string | null | undefined,
): number {
  return displayedPolicy
    ? policies.findIndex((policy: Policy) => policy.number === displayedPolicy)
    : 0;
}

const dropDownSelection = (setPolicyNumber: Function) => {
  return (option: { value: string; label: string }) => {
    sessionStorage.setItem('selectedPolicyNumber', option.value);
    setPolicyNumber(option.value);
  };
};

function renderCustomArrow(props: RenderArrowProps) {
  const { type, onClick, isEdge } = props;
  if (isEdge) {
    return <div />;
  }
  let renderedArrow: JSX.Element = (
    <SAIcon icon={SAIcons.chevronRight} size={SAIconSize.small} />
  );
  if (type === 'PREV') {
    renderedArrow = (
      <SAIcon icon={SAIcons.chevronLeft} size={SAIconSize.small} />
    );
  }
  return (
    <CarouselNavButton
      className="carouselNavButton"
      onClick={onClick}
      disabled={isEdge}
    >
      {renderedArrow}
    </CarouselNavButton>
  );
}

const PolicyCarousel = ({
  policies,
  policyNumber,
  loading,
  error,
  setPolicyNumber,
}: PolicyCarouselProps) => {
  if (loading) {
    return (
      <InnerContent>
        <SAText type="standard" text="Loading..." />
      </InnerContent>
    );
  }
  if (error) {
    return (
      <InnerContent>
        <SAText type="standard" text="Error" />
      </InnerContent>
    );
  }
  if (!policies || policies.length === 0) {
    return (
      <InnerContent>
        <SAText type="standard" text="No policies exist for your account" />
      </InnerContent>
    );
  }
  const options: any[] = listOptions(policies);
  const selectedPolicyIndex: number = findSelectedPolicy(
    policies,
    policyNumber,
  );
  const breakPoints = [
    { width: 800, itemsToShow: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1100, itemsToShow: 4 },
  ];
  return (
    <>
      <Row className="policy-carousel-component">
        <StyledCarousel
          breakPoints={breakPoints}
          isRTL={false}
          pagination={false}
          itemPosition="START"
          itemPadding={[10, 0, 0, 0]}
          itemsToScroll={1}
          initialFirstItem={selectedPolicyIndex}
          initialActiveIndex={selectedPolicyIndex}
          renderArrow={renderCustomArrow}
          focusOnSelect
          showEmptySlots
        >
          {policies?.map((policy: Policy, index: number) => {
            const formattedDate = formatDate(policy.expiration);
            return (
              <StyledCarouselItem
                className="policy-carousel-item"
                key={`${index}-${policy?.number}`}
                id={`${
                  index === selectedPolicyIndex ? 'activeCard' : 'inactiveCard'
                }`}
              >
                <div>
                  <StyledCarouselButton
                    key={policy?.number}
                    className={`${
                      index === selectedPolicyIndex ? 'active' : 'inactive'
                    } card`}
                    onClick={() => {
                      if (policy?.number) {
                        sessionStorage.setItem(
                          'selectedPolicyNumber',
                          policy.number,
                        );

                        setPolicyNumber(policy.number);
                      }

                      simpleGTMDataLayer({
                        event: 'Policy-CarouselCard-click',
                        event_action: 'Policy Carousel Card Click',
                        event_category: 'Policy Carousel Card',
                        event_label: 'Policy Carousel Card Click',
                      });

                      simpleGTMDataLayer({
                        event: 'Snowplow-Policy-CarouselCard-click',
                        event_action: 'Snowplow Policy Carousel Card Click',
                        event_category: 'Snowplow Policy Carousel Card',
                        event_label: 'Snowplow Policy Carousel Card Click',
                      });
                    }}
                  >
                    <IconHolder>
                      <Column>
                        <IconParent>
                          {matchedIconToPolicyType(
                            policy,
                            index,
                            selectedPolicyIndex,
                          )}
                        </IconParent>
                      </Column>
                      <Column>
                        <LeftAlign>
                          <SAText
                            type="standard"
                            text={policy.number}
                            weight="bold"
                            colorVariant={
                              index === selectedPolicyIndex
                                ? 'primary-foreground'
                                : 'default'
                            }
                          />
                        </LeftAlign>
                        <StyledIssuedOn>
                          <SAText
                            type="small"
                            text={`${
                              policy.type && policy.type.length > 10
                                ? `${policy.type
                                    ?.substring(0, 10)
                                    .toUpperCase()}...`
                                : policy.type?.toUpperCase()
                            } POLICY`}
                            colorVariant={
                              index === selectedPolicyIndex
                                ? 'primary-foreground'
                                : 'default'
                            }
                          />
                        </StyledIssuedOn>
                      </Column>
                      <StyledRightColumn>
                        <LeftAlign>
                          <SAText
                            type="small"
                            text="Expiration"
                            colorVariant={
                              index === selectedPolicyIndex
                                ? 'primary-foreground'
                                : 'default'
                            }
                          />
                        </LeftAlign>
                        <LeftAlignSmaller>
                          <SAText
                            type="small"
                            text={`${formattedDate}`}
                            weight="bold"
                            colorVariant={
                              index === selectedPolicyIndex
                                ? 'primary-foreground'
                                : 'default'
                            }
                          />
                        </LeftAlignSmaller>
                      </StyledRightColumn>
                    </IconHolder>
                  </StyledCarouselButton>
                  <TriangleIndicator
                    className={`${
                      index === selectedPolicyIndex
                        ? 'indicator'
                        : 'no-indicator'
                    }`}
                  />
                </div>
              </StyledCarouselItem>
            );
          })}
        </StyledCarousel>
      </Row>
      <AccountPolicyList
        className="account-policy-list"
        classNamePrefix="account-policy-list"
        options={options}
        value={options[selectedPolicyIndex]}
        onChange={dropDownSelection(setPolicyNumber)}
        isSearchable={false}
      />
    </>
  );
};

PolicyCarousel.defaultProps = {
  policies: undefined,
  displayedPolicy: undefined,
  loading: false,
  error: undefined,
};

export default PolicyCarousel;
