const customBase = {
  global: {
    breakpoints: {
      xxsmall: 0,
      xsmall: 430,
      small: 576,
      medium: 768,
      large: 992,
      xlarge: 1200,
    },
  },
};

export default customBase;
