import styled from 'styled-components';
import { SAUXTheme } from '@saux/design-system-react';
import customBase from './util/global.theme';

export const CarouselPlaceholder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        text-align: left;
        margin-top: ${theme?.size.spacer.large};
        width: 100%;
        height: 70px;
        background-color: ${theme?.color.background.background};
        `;
  }}
`;

export const PolicySummaryHolder = styled.div`
  display: flex;
  flex-direction: column;

  button {
    border-style: none;
  }

  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    min-width: 400px;
  }
`;

export const LargerSpacer = styled.div`
  width: 100%;
  height: 25px;
`;

export const Row = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    const paddingX = theme.size.spacer.large;
    const paddingY = `${(parseInt(paddingX.replace('px', ''), 10) / 3) * 2}px`;

    return `
      display: flex;
      margin-top: 50px;
      flex-direction: column;
      border-radius: ${theme.size.radius.small};
      @media (min-width: ${customBase.global.breakpoints.medium}px) {
        flex-direction: row;
        background-color: #e3e8ee;
        margin-right: -${theme.size.spacer.large};
        padding: ${paddingY} ${paddingX};
      }
    `;
  }}
`;

export const FirstColumn = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: column;
      flex: 4;
      margin-right: 0px;
      @media (min-width: ${customBase.global.breakpoints.medium}px) {
        width: 100%;
        margin-right: ${theme.size.spacer.large};
      }
    `;
  }}
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
  }
`;

export const PolicyChangesHolder = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    min-width: 400px;
  }
`;

export const DownloadPolicyDocsHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        display: flex;
        flex-direction: column;
        @media (min-width: ${customBase.global.breakpoints.medium}px) {
          min-width: 400px;
        }
        border-radius: ${theme?.size.radius.small};
        background-color: ${theme?.color.light.background};
        box-shadow: 0px ${theme?.size.spacer.xxs} ${theme?.size.radius.small} rgba(0, 0, 0, 0.2);

        @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
          margin-bottom: 25px;
        }
        `;
  }}
`;

export const DocumentsComponentHolder = styled.div`
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    min-width: 400px;
  }
`;

export const Page = styled.div`
  padding: 0;
  margin: 0;
`;
