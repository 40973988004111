import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import {
  Box,
  Loading,
  IconHolder,
  ButtonHolder,
  DownloadIconHolder,
} from './DownloadDocsButtonStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

export interface PolicyDownloadDocumentsProps {
  awsAppSyncClient: AWSAppSyncClient<any> | any;
  policyInfo?: PolicyInformation;
  policyDoc: PolicyDoc;
  onDocumentClick?: Function;
  accountNumber: string;
  loading?: boolean;
  error?: Error;
}

export interface PolicyInformation {
  policyType: string | null;
  policyNumber: string | null | undefined;
}

export interface PolicyDoc {
  policyDocumentKey: string | null | undefined;
}

const DownloadDocsButton = ({
  policyDoc,
  policyInfo,
  onDocumentClick,
  loading,
}: PolicyDownloadDocumentsProps) => {
  if (loading) {
    return (
      <Box>
        <Loading>
          <SAText type="standard" text="Loading..." />
        </Loading>
      </Box>
    );
  }

  return (
    <Box
      className="download-documents-button"
      onClick={() => {
        if (onDocumentClick) {
          onDocumentClick({
            policy_number: policyInfo,
            document_key: policyDoc,
          });
        }

        simpleGTMDataLayer({
          event: 'Policy-DownloadDocsButton-click',
          event_action: 'Policy Download Docs Button Click',
          event_category: 'Policy Download Docs Button',
          event_label: 'Policy Download Docs Button Click',
        });

        simpleGTMDataLayer({
          event: 'Snowplow-Policy-DownloadDocsButton-click',
          event_action: 'Snowplow Policy Download Docs Button Click',
          event_category: 'Snowplow Policy Download Docs Button',
          event_label: 'Snowplow Policy Download Docs Button Click',
        });
      }}
    >
      <IconHolder>
        <SAIcon
          icon={SAIcons.idCard}
          size={SAIconSize.medium}
          colorVariant="light"
        />
      </IconHolder>
      <ButtonHolder>
        <SAButton
          label="Download Policy Documents"
          variant="link-small"
          onClick={() => {}}
        />
      </ButtonHolder>
      <DownloadIconHolder>
        <SAIcon icon={SAIcons.download} size={SAIconSize.medium} />
      </DownloadIconHolder>
    </Box>
  );
};

DownloadDocsButton.defaultProps = {
  loading: false,
  error: undefined,
  policyInfo: {},
};

export default DownloadDocsButton;
