import React, { useEffect, useState } from 'react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import SnapshotInvoiceItemsContent, {
  CurrentSnapshotDetails,
  invoiceItemsTableColumns,
} from './SnapshotInvoiceItemsContent';
import {
  GET_SNAPSHOT_INVOICE_ITEMS,
  GET_SNAPSHOT_INVOICE_ITEMSVariables,
  GET_SNAPSHOT_INVOICE_ITEMS_account_items_policy_invoice,
} from './graphql/__generated__/GET_SNAPSHOT_INVOICE_ITEMS';
import { formatPremiumChangeMoney } from '../../../util/formatters/formatMoney';
import { formatDate } from '../../../util/formatters';
import InvoiceBreakdownModal from '../invoiceBreakdownModal';

const getSnapshotInvoiceItemsQuery = loader(
  './graphql/Get_Snapshot_Invoice_Items.graphql',
);
interface SnapshotInvoiceItemsDataContainerProps {
  currentSnapshotDetails: CurrentSnapshotDetails | null | undefined;
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
  policyNumber: string | undefined;
  invoiceBreakdownModal: boolean;
  closeModal: Function;
}

export const fetchSnapshotInvoiceItems = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  policyNumber: string | undefined,
  transactionNumber: string | undefined,
): Promise<ApolloQueryResult<GET_SNAPSHOT_INVOICE_ITEMS>> => {
  const variables: GET_SNAPSHOT_INVOICE_ITEMSVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
    job_number: transactionNumber,
  } as GET_SNAPSHOT_INVOICE_ITEMSVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_SNAPSHOT_INVOICE_ITEMS>
  > = awsAppSyncClient.query({
    query: getSnapshotInvoiceItemsQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_SNAPSHOT_INVOICE_ITEMS>>;
  return queryResult;
};

export const getTotalInvoiceAmount = (
  invoiceByJobNumber: GET_SNAPSHOT_INVOICE_ITEMS_account_items_policy_invoice[],
) => {
  return invoiceByJobNumber?.length
    ? invoiceByJobNumber?.reduce(
        (
          sum: number,
          item: GET_SNAPSHOT_INVOICE_ITEMS_account_items_policy_invoice,
        ) => sum + item?.invoice_item_amount,
        0,
      )
    : 0;
};

export const processInvoiceItemsData = (data: GET_SNAPSHOT_INVOICE_ITEMS) => {
  const { invoiceByJobNumber = [] } = data?.account?.items?.[0]?.policy;
  const totalInvoiceAmount = formatPremiumChangeMoney(
    getTotalInvoiceAmount(invoiceByJobNumber),
  );
  let processInvoiceData: (
    | string
    | number
    | Date
  )[][] = invoiceByJobNumber?.length
    ? invoiceByJobNumber
        ?.sort(
          (a, b) =>
            parseInt(b.item_date.replace('-', ''), 10) -
            parseInt(a.item_date.replace('-', ''), 10),
        )
        .map?.(
          ({
            invoice_item_amount,
            item_date,
          }: GET_SNAPSHOT_INVOICE_ITEMS_account_items_policy_invoice) => {
            const formattedAmount = formatPremiumChangeMoney(
              invoice_item_amount,
            );
            const formattedDate = formatDate(item_date) ?? '-';
            return Object.values({
              formattedDate,
              formattedAmount,
            });
          },
        )
    : [];
  processInvoiceData = processInvoiceData?.length
    ? [...processInvoiceData, ['Total Change', totalInvoiceAmount]]
    : [];
  return processInvoiceData;
};

const SnapshotInvoiceItemsDataContainer = ({
  awsAppSyncClient,
  accountNumber,
  policyNumber,
  currentSnapshotDetails,
  invoiceBreakdownModal,
  closeModal,
}: SnapshotInvoiceItemsDataContainerProps) => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoiceItemsInfo, setInvoiceItemsInfo] = useState<
    (string | number | Date)[][]
  >();

  useEffect(() => {
    fetchSnapshotInvoiceItems(
      awsAppSyncClient,
      accountNumber,
      policyNumber,
      currentSnapshotDetails?.transactionNumber,
    )
      .then(
        (apolloQueryResult: ApolloQueryResult<GET_SNAPSHOT_INVOICE_ITEMS>) => {
          const invoiceItemsData = processInvoiceItemsData(
            apolloQueryResult.data,
          );
          console.log('invoiceItemsData', invoiceItemsData);
          setInvoiceItemsInfo(
            invoiceItemsData?.length ? [...invoiceItemsData] : [],
          );
          setLoading(false);
        },
      )
      .catch((err: Error) => {
        console.error('GET_SNAPSHOT_INVOICE_ITEMS ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [currentSnapshotDetails?.transactionNumber]);

  return (
    <>
      {invoiceBreakdownModal ? (
        <InvoiceBreakdownModal
          invoiceItems={invoiceItemsInfo as (string | number | Date)[][]}
          error={error}
          loading={loading}
          currentSnapshotDetails={
            currentSnapshotDetails as CurrentSnapshotDetails
          }
          columns={invoiceItemsTableColumns}
          closeModal={closeModal}
        />
      ) : (
        <SnapshotInvoiceItemsContent
          error={error}
          loading={loading}
          invoiceItems={invoiceItemsInfo as (string | number | Date)[][]}
          currentSnapshotDetails={
            currentSnapshotDetails as CurrentSnapshotDetails
          }
        />
      )}
    </>
  );
};

export default SnapshotInvoiceItemsDataContainer;
