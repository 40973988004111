/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import {
  Box,
  IconHolder,
  ButtonHolder,
  LoadingContent,
} from './RedirectCustomersToAgentStyles';
import { ReactComponent as FileShieldIcon } from '../../assets/images/FileShield.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import OutageModal from '../outageModal/OutageModal';

export const customerRedirectActions = {
  CHANGE_CANCEL_POLICY: 'changeOrCancelPolicy',
  START_REINSTATE_POLICY: 'startOrReinstatePolicy',
  POLICY_COVERAGE_QUESTIONS: 'policyCoverageQuestions',
};

export interface RedirectActionProps {
  openAgentAssistanceModal?: Function;
  loading: boolean;
  error: Error | undefined;
}
export interface CustomerRedirectActionTileProps {
  action: string;
}

export const getRedirectActionLabel = (action: string) => {
  switch (action) {
    case customerRedirectActions.CHANGE_CANCEL_POLICY:
      return 'Policy change / cancel policy';
    case customerRedirectActions.START_REINSTATE_POLICY:
      return 'Start / reinstate a policy';
    case customerRedirectActions.POLICY_COVERAGE_QUESTIONS:
      return 'Coverage questions';
    default:
      return '';
  }
};

export const getGTMEventDetailsForSelectedAction = (action: string) => {
  const gtmEventDetails = {
    gtm_event: '',
    gtm_event_category: '',
    gtm_event_action: '',
    gtm_event_label: '',
  };
  switch (action) {
    case customerRedirectActions.CHANGE_CANCEL_POLICY:
      gtmEventDetails.gtm_event = 'PolicyChangeCancelPolicy-click';
      gtmEventDetails.gtm_event_category = 'Policy Change / Cancel Policy';
      gtmEventDetails.gtm_event_action = 'Policy Change / Cancel Policy click';
      gtmEventDetails.gtm_event_label = 'Policy Change / Cancel Policy click';
      break;
    case customerRedirectActions.START_REINSTATE_POLICY:
      gtmEventDetails.gtm_event = 'StartReinstatePolicy-click';
      gtmEventDetails.gtm_event_category = 'Start / Reinstate Policy';
      gtmEventDetails.gtm_event_action = 'Start / Reinstate Policy click';
      gtmEventDetails.gtm_event_label = 'Start / Reinstate Policy click';
      break;
    case customerRedirectActions.POLICY_COVERAGE_QUESTIONS:
      gtmEventDetails.gtm_event = 'CoverageQuestion-click';
      gtmEventDetails.gtm_event_category = 'Coverage Question';
      gtmEventDetails.gtm_event_action = 'Coverage Question click';
      gtmEventDetails.gtm_event_label = 'Coverage Question click';
      break;
    default:
      return { ...gtmEventDetails };
  }
  return { ...gtmEventDetails };
};

const RedirectCustomersToAgent = ({
  openAgentAssistanceModal,
  loading,
  error,
}: RedirectActionProps) => {
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const { flagDetails: PolicyCenterOutage } = useFeatureFlag(
    'PolicyCenterOutage',
  );

  if (loading) {
    return (
      <Box>
        <LoadingContent>
          <SAText type="standard" text="Loading..." />
        </LoadingContent>
      </Box>
    );
  }
  if (error) return <></>;

  const PencilIcon = () => (
    <SAIcon
      icon={SAIcons.pencil}
      size={SAIconSize.medium}
      colorVariant="light"
    />
  );

  const HelpIcon = () => (
    <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="light" />
  );

  const IconContainer = ({ action }: CustomerRedirectActionTileProps) => (
    <IconHolder>
      {action?.includes(customerRedirectActions.CHANGE_CANCEL_POLICY) && (
        <PencilIcon />
      )}
      {action?.includes(customerRedirectActions.START_REINSTATE_POLICY) && (
        <FileShieldIcon />
      )}
      {action?.includes(customerRedirectActions.POLICY_COVERAGE_QUESTIONS) && (
        <HelpIcon />
      )}
    </IconHolder>
  );

  const CustomerRedirectActionTile = ({
    action,
  }: CustomerRedirectActionTileProps) => {
    const gtmEventDetails = getGTMEventDetailsForSelectedAction(action);
    return (
      <Box
        onClick={() => {
          if (openAgentAssistanceModal) {
            if (
              action?.includes(customerRedirectActions.CHANGE_CANCEL_POLICY)
            ) {
              if (PolicyCenterOutage?.enabled) {
                setShowOutageModal(true);
              } else {
                openAgentAssistanceModal(action);
              }
            } else {
              openAgentAssistanceModal(action);
            }
            simpleGTMDataLayer({
              event: gtmEventDetails?.gtm_event,
              event_action: gtmEventDetails?.gtm_event_action,
              event_category: gtmEventDetails?.gtm_event_category,
              event_label: gtmEventDetails?.gtm_event_label,
            });

            simpleGTMDataLayer({
              event: `Snowplow-${gtmEventDetails?.gtm_event}`,
              event_action: `Snowplow ${gtmEventDetails?.gtm_event_action}`,
              event_category: `Snowplow ${gtmEventDetails?.gtm_event_category}`,
              event_label: `Snowplow ${gtmEventDetails?.gtm_event_label}`,
            });
          }
        }}
      >
        <IconContainer action={action} />
        <ButtonHolder>
          <SAButton
            label={getRedirectActionLabel(action)}
            variant="link-small"
          />
        </ButtonHolder>
      </Box>
    );
  };

  return (
    <>
      <CustomerRedirectActionTile
        action={customerRedirectActions.CHANGE_CANCEL_POLICY}
      />
      <CustomerRedirectActionTile
        action={customerRedirectActions.START_REINSTATE_POLICY}
      />
      <CustomerRedirectActionTile
        action={customerRedirectActions.POLICY_COVERAGE_QUESTIONS}
      />
      {showOutageModal && (
        <OutageModal component="Cancel Policy" setModal={setShowOutageModal} />
      )}
    </>
  );
};

export default RedirectCustomersToAgent;
