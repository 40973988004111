/* eslint-disable react/prop-types */
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import React from 'react';
import {
  AgencyDetails,
  AgencyNameHolder,
  AlertIconHolder,
  Backdrop,
  ClickableLink,
  CloseButton,
  DisclaimerNote,
  DisclaimerSection,
  EmailHolder,
  ErrorContent,
  IconSpacer,
  LoadingContent,
  MailAdjust,
  Modal,
  ModalBody,
  ModalCloseWrap,
  ModalContents,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PhoneHolder,
} from './AgentAssistanceModalStyles';
// eslint-disable-next-line import/no-cycle
import { customerRedirectActions } from '../RedirectCustomersToAgent';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone.svg';
import {
  formatEmail,
  formatPhone,
  formatTitleCase,
} from '../../../util/formatters';
import useIsResponsive from '../../../util/useIsResponsive';

export interface ModalProps {
  closeModal: Function;
  selectedCustomerAction: string;
  agencyInfo: AgencyInfo | undefined;
  loading: boolean;
  error: Error | undefined;
}

export interface ModalHeaderProps {
  headerTitle: string | undefined;
}
export interface AgencyInfo {
  name: string | null | undefined;
  phoneNumber: string | null | undefined;
  email: string | null | undefined;
}

const AgentAssistanceModal = ({
  selectedCustomerAction,
  closeModal,
  agencyInfo,
  loading,
  error,
}: ModalProps) => {
  const isResponsive = useIsResponsive();

  const formatName: Function = () => {
    if (agencyInfo?.name) {
      if (agencyInfo?.name?.length > 27) {
        return `${formatTitleCase(agencyInfo?.name?.substring(0, 27))}...`;
      }
      return formatTitleCase(agencyInfo?.name);
    }
    return null;
  };

  const formattedEmail: string | null | undefined = formatEmail(
    agencyInfo?.email,
  );
  const formattedName: string = formatName();
  const headerTitleTextType = !isResponsive ? 'heading-2' : 'heading-3';

  const ModalHeaderContainer = ({ headerTitle }: ModalHeaderProps) => (
    <ModalHeader>
      <ModalTitle>
        <SAText type={headerTitleTextType} text={headerTitle} />
      </ModalTitle>
      <ModalCloseWrap onClick={() => closeModal()}>
        <SAIcon icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" />
      </ModalCloseWrap>
    </ModalHeader>
  );

  const DisclaimerContainer = () => {
    const showFirstNote = [
      customerRedirectActions.CHANGE_CANCEL_POLICY,
      customerRedirectActions.START_REINSTATE_POLICY,
    ].includes(selectedCustomerAction);
    const showSecondNote = [
      customerRedirectActions.POLICY_COVERAGE_QUESTIONS,
    ].includes(selectedCustomerAction);
    return (
      <DisclaimerSection>
        <AlertIconHolder>
          <SAIcon
            icon={SAIcons.information}
            size={SAIconSize.medium}
            colorVariant="primary"
          />
        </AlertIconHolder>
        <DisclaimerNote>
          {showFirstNote && (
            <>
              We do not yet have self service ability for policy reinstatement,
              changes, or cancelations. Please contact your insurance agent for
              assistance.
            </>
          )}
          {showSecondNote && (
            <>
              In order to assist you in getting a timely response, please
              contact your agent for any coverage questions.
            </>
          )}
        </DisclaimerNote>
      </DisclaimerSection>
    );
  };

  const AgentContactDetailsContainer = () => {
    return (
      <AgencyDetails>
        <AgencyNameHolder>
          <SAText type="heading-3" text={`${formattedName}`} />
        </AgencyNameHolder>
        <PhoneHolder>
          <PhoneIcon />
          <IconSpacer />
          <ClickableLink href={`tel:${agencyInfo?.phoneNumber}`}>
            <SAText
              text={`${formatPhone(agencyInfo?.phoneNumber)}`}
              type="paragraph"
              weight="normal"
              colorVariant="link"
            />
          </ClickableLink>
        </PhoneHolder>
        <EmailHolder>
          <MailAdjust>
            <MailIcon />
          </MailAdjust>
          <IconSpacer />

          <ClickableLink href={`mailto:${agencyInfo?.email}`}>
            <SAText
              text={`${formattedEmail}`}
              type="paragraph"
              weight="normal"
              colorVariant="link"
            />
          </ClickableLink>
        </EmailHolder>
      </AgencyDetails>
    );
  };

  const ModalBodyContainer = () => (
    <ModalBody>
      <DisclaimerContainer />
      <AgentContactDetailsContainer />
    </ModalBody>
  );

  const ModalFooterContainer = () => (
    <ModalFooter>
      <CloseButton onClick={() => closeModal()}>
        <SAButton label="DONE" variant="primary" />
      </CloseButton>
    </ModalFooter>
  );

  if (loading) {
    return (
      <LoadingContent>
        <SAText type="standard" text="Loading..." />
      </LoadingContent>
    );
  }
  if (error) {
    return (
      <ErrorContent>
        <SAText type="standard" text="Error" />
      </ErrorContent>
    );
  }

  return (
    <Backdrop>
      <Modal>
        <ModalContents>
          <ModalHeaderContainer headerTitle="Contact Your Agent for Assistance" />
          <ModalBodyContainer />
          <ModalFooterContainer />
        </ModalContents>
      </Modal>
    </Backdrop>
  );
};

export default AgentAssistanceModal;
