import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const Box = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: 63px;
      display: flex;
      flex-direction: row;
      @media (max-width: ${customBase.global.breakpoints.medium}px) {
        margin-right: ${theme.size.spacer.large};
      }
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;

export const DocumentsHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      border-bottom: ${theme.size.spacer.medium} solid ${theme?.color.primary.foreground};
      margin-top: -${theme.size.spacer.medium};
    `;
  }}
`;

export const Document = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin-bottom: -${theme.size.spacer.medium};
    height: 63px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: ${theme.separator.line.thickness} solid ${theme?.color.background.background};
    &:hover {
        cursor: pointer;
    }
    `;
  }}
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        justify-content: center;
        text-align: center;
        width: 70px;
        height: 63px;
        margin-left: -${theme.size.spacer.medium};
        margin-top: ${theme.size.spacer.large};
        @media (min-width: ${customBase.global.breakpoints.large}px) {
          margin-left: -${theme.size.spacer.large};
        }
        `;
  }}
`;

export const ViewAllWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ViewAll = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      cursor: pointer;
      display: flex;
      flex-grow: 0;
      padding: ${theme.size.spacer.small};
      margin-right: -${theme.size.spacer.small};
      align-items: center;
    `;
  }}
`;

export const ViewAllText = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
  padding: 0;
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
`;

export const FirstHalf = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      flex-direction: column;
      margin-top: -${theme.size.spacer.medium};
      width: 100%;
    `;
  }}
`;

export const DocumentName = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-bottom: ${theme.size.spacer.xs};
      width: 100%;
    `;
  }}
`;

export const SecondHalf = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
        margin-top: -${theme.size.spacer.medium};
      `;
  }}
`;
