import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import Select from 'react-select';
import customBase from '../../util/global.theme';

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 50px;
  margin-bottom: 20px;
`;

export const SpecialInnerContent = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin: ${theme.size.spacer.small} ${theme.size.spacer.small} ${theme.size.spacer.large} ${theme.size.spacer.small};
    `;
  }}
`;

export const AssetSelect = styled(Select)`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.small}px) {
      width: 100%;
      margin-right: 0px;
    }
    width: 300px;
    margin-right: 25px;
    .policy-list__dropdown-indicator {
      font-weight: ${theme.font.primary.weight.bold};
      color: ${theme?.color.secondary.background};
    }
    `;
  }}
`;

export const AssetSelectorHolder = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .small}px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const VIN = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      font-size: 0.75rem;
      letter-spacing: 0.075rem;
      font-weight: ${theme.font.primary.weight.bold};
      @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.small}px) {
        margin: ${theme.size.spacer.medium} 0px 0px ${theme.size.spacer.xs};
      }
    `;
  }}
`;

export const LearnMore = styled.div`
  margin-bottom: 20px;
  p {
    display: inline;
  }
`;
