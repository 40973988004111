import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import {
  formatDate,
  formatTitleCase,
  formatZipcode,
} from '../../util/formatters';
import PolicySummary from './PolicySummary';
import {
  GET_POLICY_INFO,
  GET_POLICY_INFOVariables,
} from '../../generated/GET_POLICY_INFO';
import {
  GET_LAST_DAY_TO_PAY,
  GET_LAST_DAY_TO_PAYVariables,
} from '../../generated/GET_LAST_DAY_TO_PAY';
import { useFeatureFlag } from '../../util/hooks';
import { PolicyInformation } from '../../Policy';

const getPolicyInfoQuery = loader(
  '../../graphql/queries/Get_Policy_Info.graphql',
);

const getLastDayToPayQuery = loader(
  '../../graphql/queries/Get_Last_Day_To_Pay.graphql',
);

interface PolicySummaryDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  policyNumber: string;
  policyInfo: PolicyInformation | undefined;
  setPolicyInfo: Function;
}

export const fetchPolicyInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  policyNumber: any,
): Promise<ApolloQueryResult<GET_POLICY_INFO>> => {
  const variables: GET_POLICY_INFOVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
  } as GET_POLICY_INFOVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_POLICY_INFO>
  > = awsAppSyncClient.query({
    query: getPolicyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICY_INFO>>;
  return queryResult;
};

export function fetchLastDayToPayInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_LAST_DAY_TO_PAY>> {
  const variables: GET_LAST_DAY_TO_PAYVariables = {
    account_number: accountNumber,
  } as GET_LAST_DAY_TO_PAYVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_LAST_DAY_TO_PAY>
  > = awsAppSyncClient.query({
    query: getLastDayToPayQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_LAST_DAY_TO_PAY>>;
  return queryResult;
}

export const processPolicyInfo = (
  data: GET_POLICY_INFO,
  pendingCancel: boolean,
): PolicyInformation => {
  let policyType: string | null = '-';
  let policyTypeCode: string | null = '-';
  let policyNumber: string | null | undefined = '-';
  let effectiveDate: string | number | Date | null | undefined = '-';
  let expirationDate: string | number | Date | null | undefined = '-';
  let addressLine1: string = '-';
  let addressLine2: string = '-';
  let city: string = '-';
  let state: string = '-';
  let zip: string = '-';
  let status: string = '-';
  let insuredAssets: any = [];
  let otherInsured: any[] = [];

  let mailingAddress = {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
  };

  let agencyIssuingCard = {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
  };

  const policyInfoAccountItems = data?.account?.items;

  if (policyInfoAccountItems && policyInfoAccountItems.length > 0) {
    const policyInfoAccountItemsPolicy = policyInfoAccountItems[0]?.policy;
    const accountAddressItems = policyInfoAccountItems[0]?.address;
    const policytype = policyInfoAccountItemsPolicy?.policy_type;
    const policytypecode = policyInfoAccountItemsPolicy?.form ?? '-';
    const policynumber = policyInfoAccountItemsPolicy?.policy_number;
    const effectivedate = policyInfoAccountItemsPolicy?.effective_date;
    const expirationdate = policyInfoAccountItemsPolicy?.expiration_date;
    const addresslineone = accountAddressItems?.street_name;
    const addresslinetwo = '';
    const addresscity = accountAddressItems?.city;
    const addressstate = accountAddressItems?.state ?? '-';
    const addresszip = accountAddressItems?.zip_code;
    const policystatus = policyInfoAccountItemsPolicy?.status;

    const insuredassets = policyInfoAccountItemsPolicy?.insuredassets;
    const otherinsured = policyInfoAccountItemsPolicy?.insuredparties;

    const insuredAssetsItems: any[] = [];
    const otherInsuredItems: any[] = [];

    if (insuredassets && insuredassets.items.length > 0) {
      for (let i = 0; i <= insuredassets.items.length - 1; i += 1) {
        insuredAssetsItems.push({
          asset_key: insuredassets.items[i].asset_key,
          asset_name: insuredassets.items[i].asset_name,
          asset: insuredassets.items[i].asset,
        });
      }
    }

    if (otherinsured && otherinsured.items.length > 0) {
      for (let i = 0; i <= otherinsured.items.length - 1; i += 1) {
        otherInsuredItems.push({
          party_key: otherinsured.items[i].party_key,
          display_name: otherinsured.items[i].display_name,
          role_type: otherinsured.items[i].role_type,
          relation_to_primary_insured:
            otherinsured.items[i].relation_to_primary_insured,
          retired_date: otherinsured.items[i].retired_date,
          retired: otherinsured.items[i].retired,
          driver_exclusion: otherinsured.items[i].driver_exclusion,
          contact_id: otherinsured.items[i].contact_id,
        });
      }
    }

    const uniqueNamesArray: any[] = [];
    otherInsuredItems.forEach((name) => {
      if (
        uniqueNamesArray.findIndex(
          (x) => x.display_name === name.display_name,
        ) === -1
      ) {
        uniqueNamesArray.push(name);
      }
    });

    policyType = formatTitleCase(policytype);
    policyTypeCode = policytypecode;
    policyNumber = policynumber;
    effectiveDate = formatDate(effectivedate);
    expirationDate = formatDate(expirationdate);
    addressLine1 = formatTitleCase(addresslineone);
    addressLine2 = formatTitleCase(addresslinetwo);
    city = formatTitleCase(addresscity);
    state = addressstate;
    zip = formatZipcode(addresszip);
    status = pendingCancel
      ? 'Pending Cancellation'
      : formatTitleCase(policystatus);
    insuredAssets = insuredAssetsItems;
    otherInsured = otherInsuredItems;

    mailingAddress = {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
    };

    agencyIssuingCard = {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
    };
  }

  return {
    policyType,
    policyTypeCode,
    policyNumber,
    effectiveDate,
    expirationDate,
    mailingAddress,
    agencyIssuingCard,
    status,
    insuredAssets,
    otherInsured,
  };
};

const PolicySummaryDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  policyNumber,
  policyInfo,
  setPolicyInfo,
}: PolicySummaryDataContainerProps) => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingCancel, setPendingCancel] = useState<boolean>(false);
  const { flagDetails: LastDayToPay } = useFeatureFlag('LastDayToPay');

  useEffect(() => {
    if (policyNumber) {
      fetchPolicyInfo(awsAppSyncClient, accountNumber, policyNumber)
        .then((apolloQueryResult: ApolloQueryResult<GET_POLICY_INFO>) => {
          const processedPolicyInfo = processPolicyInfo(
            apolloQueryResult.data,
            pendingCancel,
          );
          setPolicyInfo(processedPolicyInfo);
          setLoading(false);
        })
        .catch((err: Error) => {
          console.error('GET_POLICY_INFO ERROR: ', err);
          setError(err);
          setLoading(false);
        });
      fetchLastDayToPayInfo(awsAppSyncClient, accountNumber)
        .then((apolloQueryResult: ApolloQueryResult<GET_LAST_DAY_TO_PAY>) => {
          if (
            apolloQueryResult?.data?.account?.items[0]?.lastDayToPay
              ?.hasPastDue &&
            apolloQueryResult?.data?.account?.items[0]?.lastDayToPay?.policy
              ?.status === 'CANCELING' &&
            LastDayToPay?.enabled
          ) {
            setPendingCancel(true);
          }
        })
        .catch((err: Error) => {
          console.error('GET_LAST_DAY_TO_PAY ERROR: ', err);
        });
    }
  }, [policyNumber, pendingCancel, LastDayToPay?.enabled]);

  return (
    <>
      <PolicySummary
        accountNumber={accountNumber}
        policyNumber={policyNumber}
        policyInfo={policyInfo}
        awsAppSyncClient={awsAppSyncClient}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default PolicySummaryDataContainer;
