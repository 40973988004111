import { SATable } from '@saux/design-system-react';
import styled from 'styled-components';
import { ReactComponent as ListIcon } from '../../assets/images/list.svg';
import customBase from '../../util/global.theme';

const breakPoint = customBase?.global?.breakpoints?.medium;

export const SnapshotTableWrapper = styled.div`
  .sa-table-accordion-wrapper {
    border-left: 4px solid #077ba7;
  }

  @media only screen and (min-width: 601px) {
    header ul li,
    article ul li {
      &:nth-child(2) {
        min-width: 90px;
        max-width: 90px;
      }
      &:nth-child(3) {
        min-width: 82px;
        max-width: 82px;
      }
      &:nth-child(4) {
        max-width: 75px;
      }
      &:nth-child(5) {
        max-width: 30px;
      }
      &:nth-child(6) {
        max-width: 30px;
      }
    }
  }
`;
export const SATableComponent = styled(SATable)`
  article {
    [data-testid='Box'] {
      & > section {
        &:hover {
          border-left: 4px solid #077ba7;
          border-radius: 0px;
        }
        @media screen and (min-width: ${breakPoint + 1}px) {
          [data-on-click-role='downloadDocument'] {
            padding: 3px;
          }
        }
        &:not(:only-child) {
          [data-on-click-role='openBreakdown'] {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

export const AccordionContentWrapper = styled.div``;
export const InvoiceItemsWrapper = styled.div`
  margin-left: 10px;
  padding-left: 10px;
`;
export const ChangeBreakdownLabel = styled.div`
  padding: 6px 0;
  display: inline-flex;
  flex-direction: row;
  & > svg {
    padding: 2px;
  }
  & > span {
    padding: 5px;
    font-weight: 700;
  }
`;
export const InvoiceDetailsTable = styled.div`
  &.display-scroll article {
    max-height: 500px;
    height: 100%;
    overflow-y: auto;
  }
  article {
    [data-testid='Box'] {
      & > section:hover {
        border-left: 4px solid transparent;
      }
      &:last-child > section,
      &:last-child > section:hover {
        background-color: #619424;
        border-radius: 0px;
        span {
          color: #ffffff;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }
`;
export const ListIconComp = styled(ListIcon)``;
export const LoadingWrapper = styled.div`
  margin: 1px;
  padding: 10px;
`;
export const ErrorWrapper = styled.div`
  margin: 1px;
  padding: 10px;
`;
export const ActionContent = styled.div``;
export const ClickableLink = styled.a`
  color: #0173c6;
  @media screen and (max-width: ${breakPoint}px) {
    &:first-of-type {
      border-right: 1px solid #0173c6;
      padding-right: 7px;
      margin-right: 3px;
    }
    &:last-of-type {
      margin-left: 3px;
      padding-left: 2px;
    }
  }
`;
