import React, { useState } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import {
  SATabBar,
  SATable,
  SATableColumn,
  SATag,
  SAText,
} from '@saux/design-system-react';
import { Header, LearnMore } from './PolicyDetailsStyles';
import { PolicyAssetsChange } from '..';
import { formatTitleCase } from '../../util/formatters';
import {
  matchedPolicyTypeCodeToPolicyType,
  matchedTagToPolicyStatus,
} from '../policySummary/PolicySummary';
import {
  RowCenterAligned,
  ContentsTagHolder,
  Spacer,
} from '../policySummary/PolicySummaryStyles';
import { PolicyInformation } from '../../Policy';

export interface PolicyDetailsProps {
  awsAppSyncClient: AWSAppSyncClient<any> | any;
  lineCoverageInfo?: PolicyCoverages[] | any;
  assetCoverageInfo?: PolicyCoverages[] | any;
  accountNumber: string;
  policyNumber: string | any;
  policyType: string;
  policyInfo: PolicyInformation | undefined;
  assets: any[];
  loading?: boolean;
  error?: Error;
}

export interface PolicyCoverages {
  policyNumber: string | null | undefined;
  line_covg_cd: string | null | undefined;
  cov_cg: string | null | undefined;
  covg_term_cd: string | null | undefined;
  term_val_amount: string | null | undefined;
  asset: string | null | undefined;
  asset_name: string | null | undefined;
  limitAmount: string | null | undefined;
  deductibleAmount: string | null | undefined;
}

const PolicyDetails = ({
  policyType,
  assets,
  lineCoverageInfo,
  assetCoverageInfo,
  loading,
  error,
  policyInfo,
}: PolicyDetailsProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const tabHeaders = (): string[] => {
    let tabLabels: string[] = ['General', 'Additional'];
    switch (policyType?.toLowerCase()) {
      case 'auto':
        tabLabels = ['General', 'Vehicle'];
        return tabLabels;
      case 'home':
        tabLabels = ['Property', 'Liability'];
        return tabLabels;
      default:
        break;
    }
    return tabLabels;
  };

  const tableHeaders: SATableColumn[] = [
    {
      align: 'left',
      name: 'Coverage',
    },
    {
      align: 'center',
      name: 'Limit',
    },
    {
      align: 'center',
      name: 'Deductible',
    },
  ];

  const onTabBarChange = (newActiveIndex: number) => {
    setActiveIndex(newActiveIndex);
  };

  if (loading) {
    return <SAText type="standard" text="Loading..." />;
  }
  if (error) {
    return <SAText type="standard" text="Error" />;
  }
  if (lineCoverageInfo?.length > 0 || assetCoverageInfo?.length > 0) {
    const tableData: any[][] = [];
    lineCoverageInfo.forEach((coverage: PolicyCoverages) => {
      const tempArr = [];
      if (window.screen.width < 601) {
        tempArr.push(
          `${
            coverage?.cov_cg && coverage?.cov_cg?.length > 31
              ? `${coverage?.cov_cg?.substring(0, 31)}...`
              : coverage?.cov_cg
          }`,
        );
      } else tempArr.push(coverage?.cov_cg);
      if (window.screen.width < 450) {
        tempArr.push(
          `${
            coverage?.limitAmount && coverage?.limitAmount?.length > 31
              ? `${coverage?.limitAmount?.substring(0, 31)}...`
              : coverage?.limitAmount
          }`,
        );
      } else tempArr.push(coverage?.limitAmount);

      tempArr.push(coverage?.deductibleAmount);
      tableData.push(tempArr);
    });
    const renderContent = (returnIndex: number) => {
      switch (returnIndex) {
        case 1:
          return (
            <PolicyAssetsChange
              assetCoverageInfo={assetCoverageInfo}
              assets={assets}
              policyType={policyType}
            />
          );
        default:
          return (
            <SATable
              columns={tableHeaders}
              data={tableData}
              variant="table-to-listview"
            />
          );
      }
    };
    return (
      <>
        <RowCenterAligned>
          <SAText
            colorVariant="primary"
            type="standard"
            text={`${matchedPolicyTypeCodeToPolicyType(policyInfo)} Policy: ${
              policyInfo?.policyNumber ?? '-'
            }`}
            weight="bold"
          />
          <ContentsTagHolder>
            <SATag
              test-attr="policy-status-tag"
              variant={matchedTagToPolicyStatus(
                policyInfo ?? ({} as PolicyInformation),
              )}
              label={`${formatTitleCase(
                formatTitleCase(policyInfo?.status) === 'Inforce'
                  ? 'In Force'
                  : policyInfo?.status,
              )}`}
            />
          </ContentsTagHolder>
        </RowCenterAligned>
        <Spacer />
        <LearnMore>
          <SAText
            text="To learn more about your auto coverages with State Auto, please&nbsp;"
            type="paragraph"
          />
          <SAText
            text=" visit our website."
            type="paragraph"
            colorVariant="link"
            onClick={() => {
              window.open(
                'https://www.stateauto.com/insurance/individuals/auto',
                '_blank',
              );
            }}
          />
        </LearnMore>
        <Header>
          <SATabBar
            activeIndex={activeIndex}
            itemLabels={tabHeaders()}
            onChange={onTabBarChange}
          />
        </Header>
        {renderContent(activeIndex)}
      </>
    );
  }
  return <SAText type="standard" text="This policy has no current coverages" />;
};

export default PolicyDetails;
