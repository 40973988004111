import { SACard, SAText } from '@saux/design-system-react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  DownloadDocsButton,
  PolicyCarousel,
  PolicyDetails,
  PolicyDocumentsCollection,
  PolicySummary,
  SnapshotTable,
  ProductTour,
  RedirectCustomersToAgent,
} from './components';
import { GET_ACCOUNT_SYSTEM_CODE } from './generated/GET_ACCOUNT_SYSTEM_CODE';
import {
  GET_DEFAULT_POLICY,
  GET_DEFAULT_POLICYVariables,
} from './generated/GET_DEFAULT_POLICY';
import {
  CarouselPlaceholder,
  DocumentsComponentHolder,
  DownloadPolicyDocsHolder,
  FirstColumn,
  LargerSpacer,
  Page,
  PolicyChangesHolder,
  PolicySummaryHolder,
  Row,
  SecondColumn,
} from './PolicyStyles';
import { simpleGTMDataLayer } from './util/GTMHelpers';
import useIsResponsive from './util/useIsResponsive';
import { useFeatureFlag } from './util/hooks';

const getDefaultPolicyQuery = loader(
  './graphql/queries/Get_Default_Policy.graphql',
);
const getSystemCodeQuery = loader(
  './graphql/queries/Get_Account_System_Code.graphql',
);

interface PolicyProps {
  session: {
    accountNumber: string;
    awsAppSyncClient: AWSAppSyncClient<any>;
    useNavigation: Function;
  };
}

export interface PolicyInformation {
  policyType: string | null;
  policyTypeCode: string | null;
  policyNumber: string | null | undefined;
  effectiveDate: string | number | Date | null | undefined;
  expirationDate: string | number | Date | null | undefined;
  mailingAddress: PolicyAddressProps;
  agencyIssuingCard: AgencyAddressProps;
  status: string;
  insuredAssets: Asset[] | any;
  otherInsured: OtherInsured[];
}

export interface PolicyAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export interface AgencyAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export interface Asset {
  asset_name: string | undefined;
  asset_key: string | undefined;
  asset: string | undefined;
}

export interface OtherInsured {
  party_key: string | undefined;
  display_name: string | undefined;
}

export const fetchDefaultPolicy = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_DEFAULT_POLICY>> => {
  const variables: GET_DEFAULT_POLICYVariables = {
    account_number: accountNumber,
  } as GET_DEFAULT_POLICYVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DEFAULT_POLICY>
  > = awsAppSyncClient.query({
    query: getDefaultPolicyQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_DEFAULT_POLICY>>;
  return queryResult;
};

export const fetchSystemCode = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_ACCOUNT_SYSTEM_CODE>> => {
  const variables: any = {
    account_number: accountNumber,
  } as any;
  const queryResult: Promise<
    ApolloQueryResult<GET_ACCOUNT_SYSTEM_CODE>
  > = awsAppSyncClient.query({
    query: getSystemCodeQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_ACCOUNT_SYSTEM_CODE>>;
  return queryResult;
};

// eslint-disable-next-line
const Policy = ({ session }: PolicyProps) => {
  const displayedPolicy: string =
    sessionStorage.getItem('selectedPolicyNumber') ?? '';
  const { useNavigation } = session;
  const { flagDetails: PolicyCoverages } = useFeatureFlag('PolicyCoverages');

  const isResponsive = useIsResponsive();
  const [policyNumber, setPolicyNumber] = useState<string>('');
  const policyTabs = PolicyCoverages?.enabled
    ? ['Policy Summary', 'Coverage']
    : ['Policy Summary'];
  const [policyInfo, setPolicyInfo] = useState<PolicyInformation>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { flagDetails } = useFeatureFlag('CustomerRedirectionActions');
  function tabChanged(newActiveTabIndex: number) {
    setActiveTabIndex(newActiveTabIndex);
  }

  function renderContent(returnIndex: number) {
    switch (returnIndex) {
      case 1:
        return (
          <PolicyDetails
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
            policyNumber={policyNumber}
            policyInfo={policyInfo}
          />
        );
      default:
        return (
          <PolicySummary
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
            policyNumber={policyNumber}
            policyInfo={policyInfo}
            setPolicyInfo={setPolicyInfo}
          />
        );
    }
  }

  let needDefaultPolicy: boolean = false;

  if (displayedPolicy === '') {
    needDefaultPolicy = true;
  }

  useEffect(() => {
    if (!needDefaultPolicy) {
      setPolicyNumber(displayedPolicy);
    }
    if (needDefaultPolicy) {
      fetchDefaultPolicy(session.awsAppSyncClient, session.accountNumber).then(
        (apolloQueryResult: ApolloQueryResult<GET_DEFAULT_POLICY>) => {
          if (apolloQueryResult) {
            if (
              apolloQueryResult.data.account &&
              apolloQueryResult.data.account?.items &&
              apolloQueryResult.data.account?.items[0]?.policies.items
            ) {
              const policies =
                apolloQueryResult.data.account?.items[0]?.policies.items;
              const fixedPolicies = policies.filter(
                (policy) => policy?.status?.toLowerCase() !== 'cancelled',
              );
              const policyNum = fixedPolicies[0]?.policy_number ?? '';
              setPolicyNumber(policyNum);
            }
          }
        },
      );
    }
  }, []);

  useEffect(() => {
    fetchSystemCode(session.awsAppSyncClient, session.accountNumber).then(
      (apolloQueryResult: ApolloQueryResult<GET_ACCOUNT_SYSTEM_CODE>) => {
        if (apolloQueryResult) {
          if (
            apolloQueryResult.data.account &&
            apolloQueryResult.data.account?.items &&
            apolloQueryResult.data.account?.items[0]
          ) {
            const systemCode =
              apolloQueryResult.data.account.items[0].source_system_code;
            const accountType =
              systemCode === 'gwcl' ? 'COMMERCIAL' : 'PERSONAL';

            simpleGTMDataLayer({
              event: 'goal-tracking',
              event_action: 'Policy Goal Tracking',
              event_category: accountType,
            });

            simpleGTMDataLayer({
              event: 'Snowplow-GoalTracking',
              event_action: 'Snowplow Policy Goal Tracking',
              event_category: accountType,
            });
          }
        }
      },
    );
  }, [session.accountNumber]);

  const DownloadDocsButtonHolder = () => {
    return (
      <DownloadPolicyDocsHolder className="policy-download-documents-tour">
        <DownloadDocsButton
          accountNumber={session.accountNumber}
          awsAppSyncClient={session.awsAppSyncClient}
          policyNumber={policyNumber}
        />
      </DownloadPolicyDocsHolder>
    );
  };

  const CustomerRedirectActionsHolder = () => {
    return (
      <>
        <DownloadDocsButtonHolder />
        {flagDetails?.enabled && (
          <RedirectCustomersToAgent
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
          />
        )}
      </>
    );
  };

  if (policyNumber !== '') {
    return (
      <>
        <Page>
          <ProductTour
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
            useNavigation={useNavigation}
          />
          <CarouselPlaceholder>
            <SAText
              type="standard"
              text="Choose a policy to view its details"
            />
            <PolicyCarousel
              accountNumber={session.accountNumber}
              awsAppSyncClient={session.awsAppSyncClient}
              policyNumber={policyNumber}
              setPolicyNumber={setPolicyNumber}
            />
          </CarouselPlaceholder>
          <Row>
            <FirstColumn>
              {isResponsive && <CustomerRedirectActionsHolder />}
              <PolicySummaryHolder>
                <SACard
                  variant="standard-tabbed"
                  tabs={policyTabs}
                  activeTabIndex={activeTabIndex}
                  tabChangeEventHandler={tabChanged}
                >
                  {renderContent(activeTabIndex)}
                </SACard>
                <LargerSpacer />
                <SACard variant="standard" title="Policy Changes">
                  <SnapshotTable
                    accountNumber={session.accountNumber}
                    awsAppSyncClient={session.awsAppSyncClient}
                    policyNumber={policyNumber}
                  />
                </SACard>
              </PolicySummaryHolder>
            </FirstColumn>
            <SecondColumn>
              <div>
                {!isResponsive && <CustomerRedirectActionsHolder />}
                <PolicyChangesHolder>
                  <LargerSpacer />
                  <SACard variant="standard" title="Documents">
                    <DocumentsComponentHolder>
                      <PolicyDocumentsCollection
                        accountNumber={session.accountNumber}
                        awsAppSyncClient={session.awsAppSyncClient}
                        policyNumber={policyNumber}
                      />
                    </DocumentsComponentHolder>
                  </SACard>
                </PolicyChangesHolder>
              </div>
            </SecondColumn>
          </Row>
        </Page>
      </>
    );
  }
  return (
    <Page>
      <CarouselPlaceholder>
        This account has no active policies
      </CarouselPlaceholder>
    </Page>
  );
};

export default Policy;
