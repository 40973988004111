import React, { useState } from 'react';
import { SATable, SATableColumn, SAText } from '@saux/design-system-react';
import {
  AssetSelect,
  AssetSelectorHolder,
  SpecialInnerContent,
  VIN,
} from '../policyDetails/PolicyDetailsStyles';
import { PolicyCoverages } from '../policyDetails/PolicyDetails';

export interface PolicyAssetsChangeProps {
  loading?: boolean;
  assetCoverageInfo: any[];
  assets: any[];
  options: any;
  policyType: string;
}

const PolicyAssetsChange = ({
  loading,
  assetCoverageInfo,
  assets,
  options,
  policyType,
}: PolicyAssetsChangeProps) => {
  const [optionIndex, setOptionIndex] = useState(0);

  const tableHeaders: SATableColumn[] = [
    {
      align: 'left',
      name: 'Coverage',
    },
    {
      align: 'center',
      name: 'Limit',
    },
    {
      align: 'center',
      name: 'Deductible',
    },
  ];

  const dropDownSelection = (option: { value: string; label: string }) => {
    const dropDownOptions = options();
    let defaultSelection: number | any = 0;
    defaultSelection = dropDownOptions.findIndex(
      (coverage: any) => coverage?.label === option?.label,
    );
    setOptionIndex(defaultSelection);
    return defaultSelection;
  };

  if (loading) {
    return <SAText type="standard" text="Loading..." />;
  }

  if (assetCoverageInfo) {
    const tableData: any[][] = [];
    assetCoverageInfo[optionIndex]?.forEach((coverage: PolicyCoverages) => {
      const tempArr = [];
      tempArr.push(coverage?.cov_cg);
      tempArr.push(coverage?.limitAmount);
      tempArr.push(coverage?.deductibleAmount);
      tableData.push(tempArr);
    });

    return (
      <>
        {policyType.toLowerCase() === 'auto' && (
          <AssetSelectorHolder>
            {assets.length > 1 && (
              <AssetSelect
                options={options()}
                value={options()[optionIndex]}
                onChange={dropDownSelection}
                className="policy-list"
                classNamePrefix="policy-list"
                isSearchable={false}
              />
            )}
            <VIN>{`VIN: ${assetCoverageInfo[optionIndex][0].asset}`}</VIN>
          </AssetSelectorHolder>
        )}
        {assetCoverageInfo[optionIndex] ? (
          <SATable
            columns={tableHeaders}
            data={tableData}
            variant="table-to-listview"
          />
        ) : (
          <SpecialInnerContent>
            <SAText
              type="standard"
              colorVariant="default"
              text="This policy asset has no individual coverages"
            />
          </SpecialInnerContent>
        )}
      </>
    );
  }
  return (
    <SpecialInnerContent>
      <SAText
        type="standard"
        colorVariant="default"
        text="This policy has no current asset coverages"
      />
    </SpecialInnerContent>
  );
};

export default PolicyAssetsChange;
