import React, { useState } from 'react';
import { isFirefox, isIE, isSafari } from 'react-device-detect';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import { loader } from 'graphql.macro';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { ReactComponent as Loading } from '../../assets/images/Loading.svg';
import {
  Modal,
  Box,
  Backdrop,
  Close,
  Content,
  StyledFooter,
  LoadingHolder,
  Title,
  Spacer,
  ErrorHolder,
} from './useDocumentDownloadStyles';

export interface FetchDocumentProps extends Headers {
  document_key?: string;
  communication_id?: string;
  invoice_number?: string;
  policy_number?: string;
  snapshot_id?: string;
}

export default (
  awsAppSyncClient: AWSAppSyncClient<any>,
  account_number: string,
  document_info_type: string,
) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoadingMsg, setLoadingMsg] = useState(true);
  const [documentError, setDocumentError] = useState<null | Error>(null);
  const closeModal = () => {
    setDocumentError(null);
    setLoadingMsg(true);
    setShowModal(false);
  };

  let usedQuery: DocumentNode;

  switch (document_info_type) {
    case 'policy':
      usedQuery = loader('../../graphql/queries/Get_Policy_Document.graphql');
      break;
    case 'snapshot':
      usedQuery = loader(
        '../../graphql/queries/Get_Policy_Snapshot_Document.graphql',
      );
      break;
    case 'document':
    default:
      usedQuery = loader('../../graphql/queries/Get_Account_Document.graphql');
      break;
  }

  const fetchPolicyDoc = (
    accountNumber: string,
    fetchDocumentProps: FetchDocumentProps,
  ): Promise<ApolloQueryResult<any>> => {
    const variables: any = {
      account_number: accountNumber,
      ...fetchDocumentProps,
    };
    const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.query(
      {
        query: usedQuery,
        variables,
        fetchPolicy: 'no-cache',
      },
    ) as Promise<ApolloQueryResult<any>>;
    return queryResult;
  };

  const onDocumentClick = (fetchDocumentProps: FetchDocumentProps) => {
    setShowModal(true);

    fetchPolicyDoc(account_number, fetchDocumentProps)
      .then((data) => {
        setDocumentError(null);
        setShowModal(false);

        let url: string | undefined;
        switch (document_info_type) {
          case 'policy':
            url = data?.data?.account?.items[0]?.policy?.document?.url;
            break;
          case 'snapshot':
            url =
              data?.data?.account?.items[0]?.policy?.snapshot?.document?.url;
            break;
          case 'document':
          default:
            url =
              data?.data?.account?.items[0]?.account_document?.document?.url;
            break;
        }

        if (url && !isFirefox && !isSafari) {
          if (window.screen.width <= 750) {
            window.location.assign(url);
          } else {
            const newWindow = window.open('about:blank');
            const p = newWindow?.document.body.appendChild(
              document.createElement('p'),
            ) as any;

            p.innerHTML = 'Loading...';

            p?.setAttribute(
              'style',
              'font-size: 32px;display: inline-block;margin-top: 50px;',
            );
            newWindow?.location.replace(url);
          }
        } else if (url && (isFirefox || isSafari)) {
          window.location.assign(url);
        }
      })
      .catch(() => {
        setDocumentError(new Error('Failed to open window'));
      });
  };

  let documentsModal: JSX.Element = <div />;
  if (showModal) {
    documentsModal = (
      <Backdrop onClick={() => closeModal()}>
        <Modal>
          {!documentError && !isIE && (
            <Content>
              {showLoadingMsg && (
                <LoadingHolder>
                  <Loading title="Loading" width="100%" height="150px" />
                </LoadingHolder>
              )}
              <Box>{showLoadingMsg && 'Preparing your document...'}</Box>
            </Content>
          )}

          {documentError && (
            <>
              <Title>
                <SAIcon icon={SAIcons.alert} size={SAIconSize.large} />
                <Spacer />
                <SAText
                  type="heading-2"
                  weight="bold"
                  text="Download Policy Documents"
                />
              </Title>
              <Content>
                <ErrorHolder>
                  <SAText
                    type="heading-3"
                    weight="normal"
                    text="Error preparing document."
                  />
                </ErrorHolder>
              </Content>
            </>
          )}

          {isIE && (
            <>
              <Title>
                <SAIcon icon={SAIcons.alert} size={SAIconSize.large} />
                <Spacer />
                <SAText
                  type="heading-2"
                  weight="bold"
                  text="Download Policy Documents"
                />
              </Title>
              <Content>
                <SAText
                  type="heading-3"
                  weight="normal"
                  text="Downloading documents is currently not supported in Internet Explorer"
                />
                <SAText
                  type="heading-3"
                  weight="normal"
                  text="Please use a more current browser such a Microsoft Edge, Google Chrome or Firefox."
                />
              </Content>
            </>
          )}

          <StyledFooter>
            <Close>
              <SAButton
                label="CLOSE"
                onClick={() => closeModal()}
                variant="primary"
              />
            </Close>
          </StyledFooter>
        </Modal>
      </Backdrop>
    );
  }
  return {
    onDocumentClick,
    documentsModal,
  };
};
