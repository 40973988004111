/**
 * This utility is a helper file that houses common functions and data types for pagination
 */

export enum PaginationNavigation {
  FIRST = 'First',
  NEXT = 'Next',
  PREVIOUS = 'Previous',
  LAST = 'Last',
}

export interface CurrentPageItemsProps<T> {
  paginationNav: PaginationNavigation;
  fullListOfItems: T[];
  totalPages: number;
  selectedPageIndex: number;
  startIndex: number;
  lastIndex: number;
}

export function currentPageItems<T>({
  paginationNav,
  fullListOfItems,
  totalPages,
  selectedPageIndex,
  startIndex,
  lastIndex,
}: CurrentPageItemsProps<T>) {
  let beginningIndex = startIndex;
  let finalIndex = lastIndex;
  let currentItems: T[];
  switch (paginationNav) {
    case PaginationNavigation.LAST:
      beginningIndex = (totalPages - 1) * 2 + 1;
      currentItems = fullListOfItems.slice(beginningIndex);
      break;
    case PaginationNavigation.PREVIOUS:
    case PaginationNavigation.NEXT:
      beginningIndex = selectedPageIndex * 2 + 1;
      finalIndex = beginningIndex + 3;
      currentItems = fullListOfItems.slice(beginningIndex, finalIndex);
      break;
    case PaginationNavigation.FIRST:
    default:
      currentItems = fullListOfItems.slice(beginningIndex, finalIndex);
      break;
  }
  return currentItems;
}
