/* eslint-disable */
import React, { useState } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPagination,
  SATag,
  SAText,
} from '@saux/design-system-react';
import {
  Box,
  InnerBox,
  Spacer,
  Row,
  RowCenterAligned,
  SummaryGrayBox,
  SmallSpacer,
  BigSpacer,
  SecondNestedColumn,
  SecondNestedRow,
  FirstLine,
  ContentsTagHolder,
  AltSmallSpacer,
  VIN,
  StyledAssetsList,
  SimpleRow,
  SimpleColumn,
  AssetIconHolder,
  AssetNameHolder,
  VINHolder,
  VINValueHolder,
  AssetHolder,
  AssetValueHolder,
  LineWrapAsset,
  AltAssetNameHolder,
  UmbrellaAddressAssetHolder,
  SummaryGrayBoxInnerContent,
  InnerBoxContent,
  MultipleAssetHolder,
  SingleAssetHolder,
  SpecialSecondNestedColumn,
  OtherInsuredSmallSpacer,
  PaginationWrap,
  VehicleItem,
  ExcludedSpacing,
  RelationSpacer,
  VehicleInfoLabel,
  ContentSize,
  ContentsTag,
  NameText
} from './PolicySummaryStyles';
import { ReactComponent as FarmRanchIcon } from '../../assets/images/FarmRanch.svg';
import { formatTitleCase, formatZipcode } from '../../util/formatters';
import { useFeatureFlag } from '../../util/hooks';
import { PaginationNavigation } from '../../util/paginationHelper';
import { Asset, PolicyInformation } from '../../Policy';
import {
  GET_POLICY_INFO_account_items_policy_insuredparties_items,
} from '../../generated/GET_POLICY_INFO'

export interface PolicySummaryProps {
  awsAppSyncClient: AWSAppSyncClient<any> | any;
  policyInfo?: PolicyInformation;
  accountNumber: string;
  policyNumber: string | any;
  loading?: boolean;
  error?: Error;
}
interface drlist {
  party_key: string;
  display_name: string | null;
  role_type: string | null;
  relation_to_primary_insured: string | null;
  retired_date: any | null;
  retired: string | null;
  driver_exclusion: string | null;
  contact_id: string | null;
}

export const sortDrivers = (driverList: drlist[]) => {
  const returnedList: drlist[] = [];
  let namesList = [];
  let i;
  for (i = 0; i < driverList.length; i++) {
    namesList[i] = driverList[i].display_name;
  }
  namesList = namesList.sort();
  for (i = 0; i < namesList.length; i++) {
    for (let j = 0; j < driverList.length; j++) {
      if (driverList[j].display_name === namesList[i]) {
        returnedList.push(driverList[j]);
        break;
      }
    }
  }
  return returnedList;
};
export const matchedTagToPolicyStatus = (policyInfo: PolicyInformation) => {
  switch (policyInfo.status ? policyInfo.status.toLowerCase() : '') {
    case 'pending cancellation':
      return 'alert-outline';
    case 'cancelled':
      return 'alert-outline';
    case 'expired':
      return 'alert-outline';
    case 'bound':
      return 'secondary-outline';
    case 'renewal':
      return 'secondary-outline';
    case 'inforce':
      return 'secondary-outline';
    case 'rescinded':
      return 'secondary-outline';
    default:
      return 'standard-outline';
  }
};

export const matchedPolicyTypeCodeToPolicyType = (
  policy: PolicyInformation | any,
): string => {
  switch (policy.policyType) {
    case 'Home':
      if (policy.policyTypeCode === 'DP3') return 'Dwelling Fire';
      else if (policy.policyTypeCode === 'HO4') return 'Renters';
      else if (policy.policyTypeCode === 'HO6') return 'Condo';
      else return 'Home';
    case 'Auto':
      return 'Auto';
    case 'Umbrella':
      return 'Umbrella';
    default:
      return policy.policyType ?? '-';
  }
};

export const displayIcon = (asset: Asset, policyInfo: PolicyInformation) => {
  if (asset?.asset_key?.includes('GWCL-FARM')) {
    return <FarmRanchIcon />;
  }
  if (policyInfo?.policyType?.toLowerCase()?.includes('umbrella')) {
    return <SAIcon icon={SAIcons.umbrella} size={SAIconSize.medium} />;
  }
  if (asset?.asset_name?.toLowerCase()?.includes('location')) {
    return <SAIcon icon={SAIcons.home} size={SAIconSize.medium} />;
  }
  return <SAIcon icon={SAIcons.auto} size={SAIconSize.medium} />;
};

// eslint-disable-next-line
const PolicySummary = ({
  awsAppSyncClient,
  policyInfo,
  accountNumber,
  loading,
  error,
}: PolicySummaryProps) => {
  const itemsPerPage = 8;
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [
    assetsTablePaginationNav,
    setAssetsTablePaginationNav,
  ] = useState<PaginationNavigation>(PaginationNavigation.FIRST);

  const { flagDetails } = useFeatureFlag('Policy Other Insured Box');

  const totalAssets: number = policyInfo?.insuredAssets.length;
  const totalPages = Math.ceil(totalAssets / 8);
  let currentAssets: Asset[];

  function assetsTablePageChange(newCurrentPageIndex: number) {
    if (newCurrentPageIndex === 0) {
      setAssetsTablePaginationNav(PaginationNavigation.FIRST);
    } else if (totalPages - 1 === newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.LAST);
    } else if (selectedPageIndex < newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.NEXT);
    } else if (selectedPageIndex > newCurrentPageIndex) {
      setAssetsTablePaginationNav(PaginationNavigation.PREVIOUS);
    }
    setSelectedPageIndex(newCurrentPageIndex);
  }

  let startIndex: number = 0;
  let lastIndex: number = 8;

  switch (assetsTablePaginationNav) {
    case PaginationNavigation.LAST:
      startIndex = selectedPageIndex * 8;
      lastIndex = selectedPageIndex * 8 + 8;
      currentAssets = policyInfo?.insuredAssets.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.PREVIOUS:
      startIndex = selectedPageIndex * 8;
      lastIndex = selectedPageIndex * 8 + 8;
      currentAssets = policyInfo?.insuredAssets.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.NEXT:
      startIndex = selectedPageIndex * 8;
      lastIndex = selectedPageIndex * 8 + 8;
      currentAssets = policyInfo?.insuredAssets.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.FIRST:
    default:
      currentAssets = policyInfo?.insuredAssets.slice(startIndex, lastIndex);
      break;
  }

  if (loading) {
    return (
      <Box>
        <InnerBox>
          <Row>
            <SAText type="standard" text="Loading..." />
          </Row>
        </InnerBox>
      </Box>
    );
  }
  if (error) {
    return (
      <Box>
        <InnerBox>
          <Row>
            <SAText type="standard" text="Error" />
          </Row>
        </InnerBox>
      </Box>
    );
  }

  let uniqueDrivers: (GET_POLICY_INFO_account_items_policy_insuredparties_items | any)[] = [];
  const uniqueNames: (string | null)[] = [];
  let contact: string | null = '';
  const items: (any | null)[] = policyInfo?.otherInsured ?? [];
  items.forEach(item => {
    if (item?.role_type === 'POLICYPRINAMEDINSURED' && contact === '') {
      contact = item?.contact_id;
    }
    if (
      (item?.retired && item?.retired === 'N') ||
      item?.retired_date === null
    ) {
      if (
        !uniqueNames.includes(item?.display_name as string) &&
        item?.role_type === 'POLICYDRIVER'
      ) {
        uniqueNames.push(item?.display_name);
        uniqueDrivers.push(item);
      }
    }
  });

  uniqueDrivers = sortDrivers(uniqueDrivers);
 
  return (
    <Box className="policy-summary-component">
      <RowCenterAligned>
        <SAText
          colorVariant="primary"
          type="standard"
          text={`${matchedPolicyTypeCodeToPolicyType(policyInfo)} Policy: ${policyInfo?.policyNumber ?? '-'
            }`}
          weight="bold"
        />
        <ContentsTagHolder>
          <SATag
            test-attr="policy-status-tag"
            variant={matchedTagToPolicyStatus(
              policyInfo ?? ({} as PolicyInformation),
            )}
            label={`${formatTitleCase(
              formatTitleCase(policyInfo?.status) === 'Inforce' || 'Rescinded'
                ? 'In Force'
                : policyInfo?.status,
            )}`}
          />
        </ContentsTagHolder>
      </RowCenterAligned>
      <Spacer />
      <RowCenterAligned>
        <SecondNestedColumn>
          <SAText type="small" text="Mailing Address" weight="bold" />
          <AltSmallSpacer />
          <SecondNestedRow>
            <FirstLine>
              <SAText
                type="standard"
                text={policyInfo?.mailingAddress.addressLine1}
              />
              <SAText
                type="standard"
                text={policyInfo?.mailingAddress.addressLine2}
              />
            </FirstLine>
            <SAText
              type="standard"
              text={`${policyInfo?.mailingAddress.city}, ${policyInfo?.mailingAddress.state} ${policyInfo?.mailingAddress.zip}`}
            />
          </SecondNestedRow>
        </SecondNestedColumn>
        {flagDetails?.enabled &&
          policyInfo?.otherInsured &&
          policyInfo.otherInsured.length > 0 ? (
          <SpecialSecondNestedColumn>
            <BigSpacer />
            <SAText type="small" text="Other Insured" weight="bold" />
            <OtherInsuredSmallSpacer />
            <SecondNestedRow>
              {policyInfo.otherInsured.map((insured: any) => {
                return (
                  <div>
                    <SAText
                      type="standard"
                      text={formatTitleCase(insured?.display_name ?? '')}
                    />
                  </div>
                );
              })}
            </SecondNestedRow>
          </SpecialSecondNestedColumn>
        ) : (
          <SecondNestedColumn />
        )}
        <SummaryGrayBox>
          <SummaryGrayBoxInnerContent>
            <InnerBoxContent>
              <SAText type="small" text="Effective Date" weight="bold" />
              <SmallSpacer />
              <SAText
                type="standard"
                text={`${policyInfo?.effectiveDate ? policyInfo?.effectiveDate : '-'
                  }`}
              />
            </InnerBoxContent>
            <InnerBoxContent>
              <SAText type="small" text="Expiration Date" weight="bold" />
              <SmallSpacer />
              <SAText
                type="standard"
                text={`${policyInfo?.expirationDate ? policyInfo?.expirationDate : '-'
                  }`}
              />
            </InnerBoxContent>
          </SummaryGrayBoxInnerContent>
        </SummaryGrayBox>
      </RowCenterAligned>
      {policyInfo?.insuredAssets?.length > 0 ? (
        <div>
          <Row style={{ paddingTop: '50px' }}>
            <div>
              {policyInfo?.policyType === 'Auto' ? (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text={`${policyInfo?.insuredAssets?.length === 1
                    ? 'Vehicle'
                    : 'Vehicles'
                    }`}
                  weight="bold"
                />
              ) : policyInfo?.policyType === 'Home' ? (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text={`${policyInfo?.insuredAssets?.length === 1
                    ? 'Location'
                    : 'Locations'
                    }`}
                  weight="bold"
                />
              ) : (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text={`${policyInfo?.insuredAssets?.length === 1
                    ? 'Insured Asset'
                    : 'Insured Assets'
                    }`}
                  weight="bold"
                />
              )}
            </div>
          </Row>
          <div>
            <Spacer />
          </div>
          <StyledAssetsList>
            {currentAssets.map((asset: Asset) => {
              const splitAddress =
                formatTitleCase(asset.asset_name).indexOf('Location') !== -1
                  ? asset?.asset?.split(' - ')
                  : '';
              if (splitAddress && splitAddress.length > 2) {
                const lowerAddressRange = splitAddress.shift();
                const remainderOfStreetAddress = splitAddress.shift();
                splitAddress.unshift(`${lowerAddressRange} - ${remainderOfStreetAddress}`);
              }
              const splitSecondAddress =
                splitAddress && splitAddress[0] !== ''
                  ? splitAddress[1].split(', ')
                  : '';
              const splitThirdAddress =
                splitSecondAddress && splitSecondAddress[0] !== ''
                  ? splitSecondAddress[1].split(' ')
                  : '';
              if (currentAssets.length < 2) {
                return (
                  <SingleAssetHolder key={asset.asset_key}>
                    <SimpleRow>
                      <SimpleColumn>
                        <AssetIconHolder>
                          {displayIcon(asset, policyInfo as PolicyInformation)}
                        </AssetIconHolder>
                      </SimpleColumn>
                      <SimpleColumn>
                        {formatTitleCase(asset.asset_name) !== 'Location' ? (
                          <AssetNameHolder>
                            <Box>
                              <SAText
                                type="standard"
                                text={`${asset && asset?.asset_name
                                  ? asset?.asset_name?.length > 23
                                    ? formatTitleCase(
                                      asset?.asset_name.substring(0, 23),
                                    ) + '...'
                                    : formatTitleCase(asset?.asset_name)
                                  : ''
                                  }`}
                                weight="bold"
                              />
                            </Box>
                          </AssetNameHolder>
                        ) : (
                          <AltAssetNameHolder style={{ marginTop: '13px' }}>
                            <Box>
                              <Box
                                style={{ marginBottom: '-3px', padding: '0px' }}
                              >
                                <SAText
                                  type="standard"
                                  weight="bold"
                                  text={`${splitAddress
                                    ? formatTitleCase(splitAddress[0])
                                    : ''
                                    },`}
                                />
                              </Box>
                              <LineWrapAsset>
                                <SAText
                                  type="standard"
                                  text={`${splitAddress
                                    ? `${formatTitleCase(
                                      splitSecondAddress[0],
                                    )}, ${splitThirdAddress[0]
                                    } ${formatZipcode(
                                      splitThirdAddress[1],
                                    )}`
                                    : ''
                                    }`}
                                />
                              </LineWrapAsset>
                            </Box>
                          </AltAssetNameHolder>
                        )}
                      </SimpleColumn>
                    </SimpleRow>
                    {formatTitleCase(policyInfo?.policyType) === 'Auto' ? (
                      <SimpleRow>
                        <SimpleColumn>
                          <VINHolder>
                            <SAText type="small" weight="bold" text="VIN: " />
                          </VINHolder>
                        </SimpleColumn>
                        <SimpleColumn>
                          <VINValueHolder>
                            <VIN>{asset?.asset}</VIN>
                          </VINValueHolder>
                        </SimpleColumn>
                      </SimpleRow>
                    ) : (
                      <SimpleRow>
                        <SimpleColumn>
                          <AssetHolder>
                            {formatTitleCase(policyInfo?.policyType) ===
                              'Home' ? (
                              <Box />
                            ) : formatTitleCase(policyInfo?.policyType) ===
                              'Umbrella' &&
                              asset &&
                              asset.asset?.indexOf('LOCATION') === -1 ? (
                              <Box />
                            ) : (
                              <SAText
                                type="small"
                                weight="bold"
                                text="Asset Identifier: "
                              />
                            )}
                          </AssetHolder>
                        </SimpleColumn>
                        <SimpleColumn>
                          {formatTitleCase(policyInfo?.policyType) ===
                            'Home' ? (
                            <Box />
                          ) : formatTitleCase(policyInfo?.policyType) ===
                            'Umbrella' &&
                            asset &&
                            asset.asset?.indexOf('LOCATION') === -1 ? (
                            <UmbrellaAddressAssetHolder>
                              <Box style={{ marginBottom: '-7px' }}>
                                <SAText
                                  type="small"
                                  text={`${splitAddress
                                    ? formatTitleCase(splitAddress[0])
                                    : ''
                                    },`}
                                />
                              </Box>
                              <LineWrapAsset>
                                <SAText
                                  type="small"
                                  text={`${splitAddress
                                    ? `${formatTitleCase(
                                      splitSecondAddress[0],
                                    )}, ${splitThirdAddress[0]
                                    } ${formatZipcode(
                                      splitThirdAddress[1],
                                    )}`
                                    : ''
                                    }`}
                                />
                              </LineWrapAsset>
                            </UmbrellaAddressAssetHolder>
                          ) : (
                            <AssetValueHolder>
                              <VIN>
                                {asset && asset?.asset
                                  ? asset?.asset?.length > 18
                                    ? asset?.asset.substring(0, 18) + '...'
                                    : asset?.asset
                                  : ''}
                              </VIN>
                            </AssetValueHolder>
                          )}
                        </SimpleColumn>
                      </SimpleRow>
                    )}
                  </SingleAssetHolder>
                );
              }
              return (
                <MultipleAssetHolder key={asset.asset_key}>
                  <SimpleRow>
                    <SimpleColumn>
                      <AssetIconHolder>
                        {displayIcon(asset, policyInfo as PolicyInformation)}
                      </AssetIconHolder>
                    </SimpleColumn>
                    <SimpleColumn>
                      {formatTitleCase(asset.asset_name) !== 'Location' ? (
                        <AssetNameHolder>
                          <Box>
                            <SAText
                              type="standard"
                              text={`${asset && asset?.asset_name
                                ? asset?.asset_name?.length > 23
                                  ? formatTitleCase(
                                    asset?.asset_name.substring(0, 23),
                                  ) + '...'
                                  : formatTitleCase(asset?.asset_name)
                                : ''
                                }`}
                              weight="bold"
                            />
                          </Box>
                        </AssetNameHolder>
                      ) : (
                        <AltAssetNameHolder style={{ marginTop: '13px' }}>
                          <Box>
                            <Box
                              style={{ marginBottom: '-3px', padding: '0px' }}
                            >
                              <SAText
                                type="standard"
                                weight="bold"
                                text={`${splitAddress
                                  ? formatTitleCase(splitAddress[0])
                                  : ''
                                  },`}
                              />
                            </Box>
                            <LineWrapAsset>
                              <SAText
                                type="standard"
                                text={`${splitAddress
                                  ? `${formatTitleCase(
                                    splitSecondAddress[0],
                                  )}, ${splitThirdAddress[0]
                                  } ${formatZipcode(splitThirdAddress[1])}`
                                  : ''
                                  }`}
                              />
                            </LineWrapAsset>
                          </Box>
                        </AltAssetNameHolder>
                      )}
                    </SimpleColumn>
                  </SimpleRow>
                  {formatTitleCase(policyInfo?.policyType) === 'Auto' ? (
                    <SimpleRow>
                      <SimpleColumn>
                        <VINHolder>
                          <SAText type="small" weight="bold" text="VIN: " />
                        </VINHolder>
                      </SimpleColumn>
                      <SimpleColumn>
                        <VINValueHolder>
                          <VIN>{asset?.asset}</VIN>
                        </VINValueHolder>
                      </SimpleColumn>
                    </SimpleRow>
                  ) : (
                    <SimpleRow>
                      <SimpleColumn>
                        <AssetHolder>
                          {formatTitleCase(policyInfo?.policyType) ===
                            'Home' ? (
                            <Box />
                          ) : formatTitleCase(policyInfo?.policyType) ===
                            'Umbrella' &&
                            asset &&
                            asset.asset?.indexOf('LOCATION') === -1 ? (
                            <Box />
                          ) : (
                            <SAText
                              type="small"
                              weight="bold"
                              text="Asset Identifier: "
                            />
                          )}
                        </AssetHolder>
                      </SimpleColumn>
                      <SimpleColumn>
                        {formatTitleCase(policyInfo?.policyType) === 'Home' ? (
                          <Box />
                        ) : formatTitleCase(policyInfo?.policyType) ===
                          'Umbrella' &&
                          asset &&
                          asset.asset?.indexOf('LOCATION') === -1 ? (
                          <UmbrellaAddressAssetHolder>
                            <Box style={{ marginBottom: '-7px' }}>
                              <SAText
                                type="small"
                                text={`${splitAddress
                                  ? formatTitleCase(splitAddress[0])
                                  : ''
                                  },`}
                              />
                            </Box>
                            <LineWrapAsset>
                              <SAText
                                type="small"
                                text={`${splitAddress
                                  ? `${formatTitleCase(
                                    splitSecondAddress[0],
                                  )}, ${splitThirdAddress[0]
                                  } ${formatZipcode(splitThirdAddress[1])}`
                                  : ''
                                  }`}
                              />
                            </LineWrapAsset>
                          </UmbrellaAddressAssetHolder>
                        ) : (
                          <AssetValueHolder>
                            <VIN>
                              {asset && asset?.asset
                                ? asset?.asset?.length > 18
                                  ? asset?.asset.substring(0, 18) + '...'
                                  : asset?.asset
                                : ''}
                            </VIN>
                          </AssetValueHolder>
                        )}
                      </SimpleColumn>
                    </SimpleRow>
                  )}
                </MultipleAssetHolder>
              );
            })}
          </StyledAssetsList>
          <PaginationWrap>
            <SAPagination
              currentPageIndex={selectedPageIndex}
              totalItems={totalAssets}
              itemsPerPage={itemsPerPage}
              onChange={assetsTablePageChange}
            />
          </PaginationWrap>

          {policyInfo?.policyType === "Auto" &&
            uniqueDrivers.length !== 0 && (
              <Row style={{ paddingTop: '30px', display: 'flex', flexDirection: 'column' }}>
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text="Listed Drivers"
                  weight="bold"
                />
                <div>
                  <Spacer />
                </div>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  {uniqueDrivers.map(item => (
                    <VehicleItem>
                      <div style={{ padding: '12px', marginLeft: '12px', marginRight: '12px' }}>
                        <SAIcon icon={SAIcons.person} size={SAIconSize.medium} />
                      </div>
                      <div>
                        <ExcludedSpacing>
                          <NameText>{formatTitleCase(item.display_name)}</NameText>
                        </ExcludedSpacing>
                        {(item.relation_to_primary_insured ||
                          item.contact_id === contact) && (
                            <RelationSpacer>
                              <VehicleInfoLabel>Relationship</VehicleInfoLabel>
                              <VIN>
                                {item.contact_id === contact ? (
                                  <div>Primary Insured</div>
                                ) : (
                                  <div>
                                    {item.relation_to_primary_insured === 'NOTRELATED'
                                      ? 'Not Related'
                                      : formatTitleCase(item.relation_to_primary_insured)}
                                  </div>
                                )}
                              </VIN>
                            </RelationSpacer>
                          )}
                      </div>
                      {item.driver_exclusion === '1' && (
                        <ContentSize>
                          <ContentsTag>
                            Excluded
                          </ContentsTag>
                        </ContentSize>
                      )}
                      <Spacer />
                    </VehicleItem>
                  ))}
                </Row>
              </Row>
            )}
        </div>
      ) : (
        <div>
          <Row style={{ paddingTop: '50px' }}>
            <div style={{ color: '#0173C6' }}>
              {formatTitleCase(policyInfo?.policyType) === 'Auto' ? (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text="Vehicles"
                  weight="bold"
                />
              ) : formatTitleCase(policyInfo?.policyType) === 'Home' ? (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text="Locations"
                  weight="bold"
                />
              ) : (
                <SAText
                  colorVariant="primary"
                  type="standard"
                  text="Insured Assets"
                  weight="bold"
                />
              )}
            </div>
          </Row>
          <div>
            <Spacer />
          </div>
          <StyledAssetsList>
            <SAText type="standard" text="This policy has no insured assets" />
          </StyledAssetsList>
        </div>
      )}
    </Box>
  );
};

PolicySummary.defaultProps = {
  policyInfo: undefined,
  loading: false,
  error: undefined,
};

export default PolicySummary;
