/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import PolicyDetails from './PolicyDetails';
import {
  GET_POLICY_COVERAGES,
  GET_POLICY_COVERAGESVariables,
} from '../../generated/GET_POLICY_COVERAGES';
import { formatMoney } from '../../util/formatters';
import { PolicyInformation } from '../../Policy';

const getPolicyCoveragesQuery = loader(
  '../../graphql/queries/Get_Policy_Coverages.graphql',
);

interface PolicyDetailsDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  policyNumber: string;
  policyInfo: PolicyInformation | undefined;
}

export const fetchPolicyCoverages = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  policyNumber: any,
): Promise<ApolloQueryResult<GET_POLICY_COVERAGES>> => {
  const variables: GET_POLICY_COVERAGESVariables = {
    account_number: accountNumber,
    policy_number: policyNumber,
  } as GET_POLICY_COVERAGESVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_POLICY_COVERAGES>
  > = awsAppSyncClient.query({
    query: getPolicyCoveragesQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICY_COVERAGES>>;
  return queryResult;
};

export const processPolicyCoverages = (data: GET_POLICY_COVERAGES) => {
  let policyNumber: string | null | undefined = '-';
  let policyType: string | null | undefined = '-';
  let line_covg_cd: string | null | undefined = '-';
  let cov_cg: string | null | undefined = '-';
  let covg_term_cd: string | null | undefined = '-';
  let term_val_amount: string | null | undefined = '-';
  let limitAmount: number | string = '-';
  let deductibleAmount: number | string = '-';
  let asset: string | null | undefined = '-';
  let asset_name: string | null | undefined = '-';
  const streamlinedCoveragesArr: any[] = [];
  const lineCoverages: any[] = [];
  const assetCoverages: any[] = [];
  const assets: any[] = [];
  let uniqueLinesArray: any[] = [];
  let uniqueAssetsArray: any[] = [];
  let sortableUniqueArray: any[] = [];
  let tempOtherUniqueArray: any[] = [];
  let finalUniqueLinesArray: any[] = [];
  let finalUniqueAssetsArray: any[] = [];

  if (data && data.account?.items && data.account.items.length > 0) {
    const coverageData = data.account.items[0]?.policy.connect_coverages;
    const assetData = data.account.items[0]?.policy.insuredassets;

    policyNumber =
      coverageData && coverageData.items
        ? coverageData?.items[0]?.policy_number
        : '-';
    policyType = data.account.items[0]?.policy.policy_type ?? '-';

    coverageData?.items?.forEach((item) => {
      if (
        item?.retired_date === null ||
        item?.retired_date === undefined ||
        item?.retired_date?.toLowerCase().indexOf('null') !== -1
      ) {
        line_covg_cd = item?.line_covg_cd;
        cov_cg = item?.covg_cd;
        if (item?.covg_term_cd?.toLowerCase().indexOf('limit') !== -1) {
          limitAmount = item?.term_val_amt ?? '-';
        }
        if (item?.covg_term_cd?.toLowerCase().indexOf('deductible') !== -1) {
          deductibleAmount = item?.term_val_amt ?? '-';
        }
        covg_term_cd = item?.covg_term_cd;
        term_val_amount = item?.term_val_amt;
        asset = item?.asset?.toLowerCase() === 'null' ? '-' : item?.asset;
        assetData?.items?.forEach((indAsset) => {
          if (
            item?.asset === indAsset?.asset &&
            item?.asset?.toLowerCase() !== 'null'
          ) {
            asset_name = indAsset?.asset_name;
          }
        });
        streamlinedCoveragesArr.push({
          policyNumber,
          line_covg_cd,
          cov_cg,
          covg_term_cd,
          term_val_amount,
          asset,
          asset_name,
          limitAmount,
          deductibleAmount,
        });
      }
    });
    for (let i = 0; i < streamlinedCoveragesArr.length; i += 1) {
      if (streamlinedCoveragesArr[i].line_covg_cd.toLowerCase() === 'line') {
        lineCoverages.push(streamlinedCoveragesArr[i]);
      }
      if (streamlinedCoveragesArr[i].line_covg_cd.toLowerCase() === 'asset') {
        assetCoverages.push(streamlinedCoveragesArr[i]);
        assets.push(streamlinedCoveragesArr[i].asset);
      }
    }
    uniqueLinesArray = lineCoverages.filter((cov, index) => {
      const _cov = JSON.stringify(cov);
      return (
        index ===
        lineCoverages.findIndex((obj) => {
          return JSON.stringify(obj) === _cov;
        })
      );
    });

    uniqueAssetsArray = assetCoverages.filter((cov, index) => {
      const _cov = JSON.stringify(cov);
      return (
        index ===
        assetCoverages.findIndex((obj) => {
          return JSON.stringify(obj) === _cov;
        })
      );
    });

    const splitLimits = [];
    for (let i = 0; i < uniqueLinesArray.length; i += 1) {
      if (
        uniqueLinesArray[i].cov_cg &&
        uniqueLinesArray[i].cov_cg.indexOf('Coverage ') !== -1
      ) {
        sortableUniqueArray.push(uniqueLinesArray[i]);
        sortableUniqueArray.sort((a, b) => a.cov_cg.localeCompare(b.cov_cg));
      }
      if (
        (uniqueLinesArray[i].cov_cg &&
          uniqueLinesArray[i].cov_cg.toLowerCase() ===
            'uninsured / underinsured motorist bodily injury (split limit)') ||
        uniqueLinesArray[i].cov_cg.toLowerCase() === 'bodily injury'
      ) {
        splitLimits.push(uniqueLinesArray[i]);
      }
      if (
        uniqueLinesArray[i].cov_cg &&
        uniqueLinesArray[i].cov_cg.indexOf('Coverage ') === -1 &&
        uniqueLinesArray[i].cov_cg
          .toLowerCase()
          .indexOf(
            'uninsured / underinsured motorist bodily injury (split limit)',
          ) === -1 &&
        uniqueLinesArray[i].cov_cg.toLowerCase() !== 'bodily injury'
      ) {
        tempOtherUniqueArray.push(uniqueLinesArray[i]);
      }
    }

    const returnedCombinedSplitLimit: any[] = [];
    if (splitLimits.length % 2 === 0) {
      const duplicatedSplitLimit: any[] = [];
      const filteredOtherArr: any[] = [];
      let combinedAmount: any[] | any = [];
      let filteredUninsuredArr: any[] = [];
      let resultArr: any[] = [];

      for (let i = 0; i < splitLimits.length; i += 1) {
        if (
          splitLimits[i].cov_cg ===
          'Uninsured / Underinsured Motorist Bodily Injury (Split Limit)'
        ) {
          duplicatedSplitLimit.push(splitLimits[i]);
        }
        if (
          splitLimits[i].cov_cg !==
          'Uninsured / Underinsured Motorist Bodily Injury (Split Limit)'
        ) {
          filteredOtherArr.push(splitLimits[i]);
        }
        if (duplicatedSplitLimit.length > 2) {
          filteredUninsuredArr = duplicatedSplitLimit.filter(
            (x) => x.asset_name !== '-',
          );
        }
        resultArr = filteredUninsuredArr.concat(filteredOtherArr);
      }

      resultArr.length > 0 &&
        resultArr.forEach((limit: any) => {
          combinedAmount.push(limit.term_val_amount);

          let returnedAmount: string = '-';

          if (combinedAmount.length === 2) {
            const firstAmount = formatMoney(combinedAmount[0])?.replace(
              /\.00/g,
              '',
            );
            const secondAmount = formatMoney(combinedAmount[1])?.replace(
              /\.00/g,
              '',
            );
            returnedAmount = `${firstAmount} per person / ${secondAmount} per accident`;

            returnedCombinedSplitLimit.push({
              asset: limit.asset,
              asset_name: limit.asset_name,
              cov_cg: limit.cov_cg,
              covg_term_cd: limit.covg_term_cd,
              deductibleAmount: '-',
              limitAmount: returnedAmount,
              line_covg_cd: limit.line_covg_cd,
              policyNumber: limit.policyNumber,
              term_val_amount: limit.term_val_amount,
            });
            combinedAmount = [];
          }
        });
    }

    finalUniqueLinesArray = sortableUniqueArray
      .concat(returnedCombinedSplitLimit)
      .concat(tempOtherUniqueArray)
      .concat(splitLimits);

    for (let i = 0; i < finalUniqueLinesArray.length; i += 1) {
      const coverageTitle = finalUniqueLinesArray[i]?.cov_cg;

      finalUniqueLinesArray[i].deductibleAmount =
        formatMoney(finalUniqueLinesArray[i]?.deductibleAmount)?.replace(
          /\.00/g,
          '',
        ) ?? finalUniqueLinesArray[i]?.deductibleAmount;
      if (
        coverageTitle?.toLowerCase().indexOf('bodily') !== -1 &&
        finalUniqueLinesArray[i]?.deductibleAmount !== '-'
      ) {
        finalUniqueLinesArray[i].deductibleAmount += ' per accident';
      }
      if (
        (coverageTitle?.toLowerCase().indexOf('property damage') !== -1 ||
          coverageTitle?.toLowerCase().indexOf('medical payments to others') !==
            -1) &&
        finalUniqueLinesArray[i]?.deductibleAmount !== '-'
      ) {
        finalUniqueLinesArray[i].deductibleAmount = '-';
      }

      if (
        coverageTitle?.toLowerCase().indexOf('bodily') === -1 &&
        coverageTitle?.toLowerCase().indexOf('injury') === -1 &&
        coverageTitle?.toLowerCase().indexOf('medical') === -1 &&
        coverageTitle?.toLowerCase().indexOf('roadside assistance') === -1 &&
        coverageTitle?.toLowerCase().indexOf('transportation expense') === -1 &&
        coverageTitle
          ?.toLowerCase()
          .indexOf(
            'uninsured / underinsured motorist bodily injury (split limit)',
          ) === -1
      ) {
        finalUniqueLinesArray[i].limitAmount =
          formatMoney(finalUniqueLinesArray[i]?.limitAmount)?.replace(
            /\.00/g,
            '',
          ) ?? finalUniqueLinesArray[i]?.limitAmount;
      } else if (
        coverageTitle
          ?.toLowerCase()
          .indexOf(
            'uninsured / underinsured motorist bodily injury (split limit)',
          ) !== -1
      ) {
        finalUniqueLinesArray[i].limitAmount =
          finalUniqueLinesArray[i].limitAmount;
      } else if (
        coverageTitle?.toLowerCase().indexOf('medical payments to others') !==
        -1
      ) {
        finalUniqueLinesArray[i].limitAmount = `${formatMoney(
          finalUniqueLinesArray[i].limitAmount,
        )?.replace(/\.00/g, '')} per person`;
      } else if (
        coverageTitle?.toLowerCase().indexOf('roadside assistance') !== -1 ||
        coverageTitle?.toLowerCase().indexOf('transportation expense') !== -1
      ) {
        finalUniqueLinesArray[
          i
        ].limitAmount = `${finalUniqueLinesArray[i].limitAmount} miles`;
      } else {
        finalUniqueLinesArray[i].limitAmount = `${
          formatMoney(finalUniqueLinesArray[i]?.limitAmount)?.replace(
            /\.00/g,
            '',
          ) ?? finalUniqueLinesArray[i]?.limitAmount
        }`;
      }
    }

    sortableUniqueArray = [];
    tempOtherUniqueArray = [];

    for (let i = 0; i < uniqueAssetsArray.length; i += 1) {
      if (
        uniqueAssetsArray[i].cov_cg &&
        uniqueAssetsArray[i].cov_cg.indexOf(`Coverage `) !== -1
      ) {
        sortableUniqueArray.push(uniqueAssetsArray[i]);
        sortableUniqueArray.sort((a, b) => a.cov_cg.localeCompare(b.cov_cg));
      }
      if (
        uniqueAssetsArray[i].cov_cg &&
        uniqueAssetsArray[i].cov_cg.indexOf(`Coverage `) === -1
      ) {
        tempOtherUniqueArray.push(uniqueAssetsArray[i]);
      }
    }
    finalUniqueAssetsArray = sortableUniqueArray.concat(tempOtherUniqueArray);

    for (let i = 0; i < finalUniqueAssetsArray.length; i += 1) {
      let cov_cg = finalUniqueAssetsArray[i]?.cov_cg;
      finalUniqueAssetsArray[i].deductibleAmount =
        formatMoney(finalUniqueAssetsArray[i]?.deductibleAmount)?.replace(
          /\.00/g,
          '',
        ) ?? finalUniqueAssetsArray[i]?.deductibleAmount;

      if (
        cov_cg?.toLowerCase().indexOf('bodily') === -1 &&
        cov_cg?.toLowerCase().indexOf('injury') === -1 &&
        cov_cg?.toLowerCase().indexOf('medical') === -1 &&
        cov_cg?.toLowerCase().indexOf('roadside assistance') === -1 &&
        cov_cg?.toLowerCase().indexOf('transportation expense') === -1 &&
        cov_cg?.toLowerCase().indexOf('comprehensive') === -1 &&
        cov_cg?.toLowerCase().indexOf('collision') === -1
      ) {
        finalUniqueAssetsArray[i].limitAmount =
          formatMoney(finalUniqueAssetsArray[i]?.limitAmount)?.replace(
            /\.00/g,
            '',
          ) ?? finalUniqueAssetsArray[i]?.limitAmount;
      } else if (
        cov_cg?.toLowerCase().indexOf('bodily') !== -1 ||
        cov_cg?.toLowerCase().indexOf('injury') !== -1 ||
        cov_cg?.toLowerCase().indexOf('medical') !== -1
      ) {
        finalUniqueAssetsArray[i].limitAmount = `${formatMoney(
          finalUniqueAssetsArray[i].limitAmount,
        )?.replace(/\.00/g, '')} per person`;
      } else if (
        cov_cg?.toLowerCase().indexOf('roadside assistance') !== -1 ||
        cov_cg?.toLowerCase().indexOf('transportation expense') !== -1
      ) {
        finalUniqueAssetsArray[
          i
        ].limitAmount = `${finalUniqueAssetsArray[i].limitAmount} miles`;
      } else if (
        cov_cg?.toLowerCase().indexOf('comprehensive') !== -1 ||
        cov_cg?.toLowerCase().indexOf('collision') !== -1
      ) {
        finalUniqueAssetsArray[i].limitAmount = '-';
      } else {
        finalUniqueAssetsArray[
          i
        ].limitAmount = `${finalUniqueAssetsArray[i].limitAmount}`;
      }
    }

    finalUniqueAssetsArray = finalUniqueAssetsArray.filter((item, index) => {
      const stringItem = JSON.stringify(item);
      return (
        index ===
        finalUniqueAssetsArray.findIndex((obj) => {
          return JSON.stringify(obj) === stringItem;
        })
      );
    });
  }

  return { policyType, assets, finalUniqueLinesArray, finalUniqueAssetsArray };
};

export const combineLineCoverages = (lineCoverageInfo: Array<any>) => {
  const filtered = lineCoverageInfo?.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.cov_cg === value.cov_cg &&
          t.deductibleAmount === value.deductibleAmount &&
          t.limitAmount === value.limitAmount,
      ),
  );

  const out = filtered?.reduce((a, v) => {
    if (a[v.cov_cg]) {
      a[v.cov_cg].limitAmount = `${formatMoney(
        a[v.cov_cg].term_val_amount,
        '-',
      )} / ${formatMoney(v.term_val_amount, '-')}`;
      Array.from(new Set(a[v.cov_cg].term_val_amount.split(' / '))).toString();
    } else {
      a[v.cov_cg] = v;
    }
    return a;
  }, {});
  if (out) return Object?.values(out);
  return [];
};

const PolicyDetailsDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  policyNumber,
  policyInfo,
}: PolicyDetailsDataContainerProps) => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [assetCoveragesInfo, setAssetCoveragesInfo] = useState<any>();
  const [lineCoveragesInfo, setLineCoveragesInfo] = useState<any>();
  const [policyType, setPolicyType] = useState<string>('');
  const [assets, setAssets] = useState<any>();
  const [filteredLineCoverages, setFilteredLineCoverages] = useState<
    Array<any>
  >([]);

  useEffect(() => {
    if (policyNumber) {
      fetchPolicyCoverages(awsAppSyncClient, accountNumber, policyNumber)
        .then((apolloQueryResult: ApolloQueryResult<GET_POLICY_COVERAGES>) => {
          const processedPolicyCoverage = processPolicyCoverages(
            apolloQueryResult.data,
          );
          setLineCoveragesInfo(processedPolicyCoverage.finalUniqueLinesArray);
          setAssetCoveragesInfo(processedPolicyCoverage.finalUniqueAssetsArray);
          setPolicyType(processedPolicyCoverage.policyType);
          setAssets(processedPolicyCoverage.assets);
          setLoading(false);
        })
        .catch((err: Error) => {
          console.error('GET_POLICY_COVERAGES ERROR: ', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [policyNumber]);

  useEffect(() => {
    if (lineCoveragesInfo?.length) {
      const filtered = combineLineCoverages(lineCoveragesInfo);
      setFilteredLineCoverages(filtered);
    }
  }, [lineCoveragesInfo]);

  return (
    <PolicyDetails
      accountNumber={accountNumber}
      policyNumber={policyNumber}
      awsAppSyncClient={awsAppSyncClient}
      lineCoverageInfo={filteredLineCoverages}
      assetCoverageInfo={assetCoveragesInfo}
      policyType={policyType}
      policyInfo={policyInfo}
      assets={assets}
      loading={loading}
      error={error}
    />
  );
};

export default PolicyDetailsDataContainer;
