/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SATableColumn,
  SAText,
} from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import { formatTitleCase } from '../../util/formatters';
import formatDate from '../../util/formatters/formatDate';
import formatMoney, {
  formatPremiumChangeMoney,
} from '../../util/formatters/formatMoney';
import SnapshotInvoiceItemsContent from './snapshotInvoiceItemsContent';
import {
  SATableComponent,
  SnapshotTableWrapper,
  AccordionContentWrapper,
  ClickableLink,
  ActionContent,
} from './SnapshotTableStyles';
import { CurrentSnapshotDetails } from './snapshotInvoiceItemsContent/SnapshotInvoiceItemsContent';
import useIsResponsive from '../../util/useIsResponsive';

export interface SnapshotTableProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  snapshotInfo?: SnapshotInformation | any;
  accountNumber: string | any;
  policyNumber: string | undefined;
  loading?: boolean;
  error?: Error;
  onDocumentClick?: Function;
  displayInvoiceItemsContent: boolean;
  rowExpanded: boolean[];
  setOrUpdateIsRowExpandedFlags: Function;
}

export interface DocumentsReturned {
  policyNumber: string[] | null | any;
  documentsKey: string[] | null | any;
}

export interface SnapshotInformation {
  policyKey: string[] | null;
  activityType: string[] | null;
  description: string[] | null;
  effectiveDate: string[] | number[] | Date[] | undefined | null;
  issueDate: string[] | number[] | Date[] | null | undefined;
  amountTotal: string[] | number[] | undefined | null;
  amountChange: string[] | number[] | undefined | null;
  transactionNumber: string[] | null;
  sourceSystemCode: string;
}

export interface FileInfo {
  fileType: string | null | any;
}

export interface RowDataProps {
  rowData: any[];
  rowIndex: number;
}

export enum RowExpandActions {
  SET = 'set',
  UPDATE = 'update',
}

export interface AccordionContentProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
  policyNumber: string | undefined;
  fetchCurrentSnapshotDetails: Function;
  rowIndex: number;
  snapshotInfo: SnapshotInformation;
  invoiceBreakdownModal: boolean;
}

export const processColumn3 = (value: number | string) => {
  const text = formatPremiumChangeMoney(value);
  return (
    <SAText
      type="standard"
      text={text}
      colorVariant={text.indexOf('-') === 0 ? 'alert' : 'default'}
    />
  );
};

export const processColumn4 = (value: number) => {
  let text = formatMoney(value, '-')?.toString();
  if (text === undefined) {
    text = '-';
  }
  return <SAText type="standard" text={text} />;
};

export const downloadIcon = () => {
  return (
    <SAIcon
      icon={SAIcons.download}
      size={SAIconSize.medium}
      data-on-click-role="downloadDocument"
    />
  );
};

export const chevronDownIcon = () => (
  <SAIcon
    icon={SAIcons.chevronDown}
    size={SAIconSize.medium}
    data-on-click-role="openBreakdown"
    className="expandAccordion"
  />
);

export const snapshotTableOnRowClick = (
  event: React.MouseEvent<HTMLElement>,
  onDocumentClick: Function | undefined,
  policyNumber: string | undefined,
  snapshotInfo: SnapshotInformation,
  selectedRowIndex: number,
  rowExpanded: Array<boolean>,
  setOrUpdateIsRowExpandedFlags: Function,
  isResponsive: boolean,
  showInvoiceBreakdownModal: Function,
  setCurrentSnapshotIndex: Function,
) => {
  event.preventDefault();
  const onClickRole =
    (event?.target as HTMLElement).dataset?.onClickRole ?? null;
  if (onDocumentClick && ['downloadDocument'].includes(onClickRole as string)) {
    onDocumentClick({
      policy_number: policyNumber,
      snapshot_id: snapshotInfo?.policyKey?.[selectedRowIndex],
    });
  } else if (
    !isResponsive ||
    (isResponsive && ['openBreakdown'].includes(onClickRole as string))
  ) {
    const updatedRowExpandedData =
      (rowExpanded?.length > 0 &&
        rowExpanded?.map((el: boolean, index: number) =>
          selectedRowIndex === index ? !el : el,
        )) ??
      [];
    if (isResponsive && ['openBreakdown'].includes(onClickRole as string)) {
      setCurrentSnapshotIndex(selectedRowIndex);
      showInvoiceBreakdownModal(true);
      document.body.style.overflowY = 'hidden';
    }

    setOrUpdateIsRowExpandedFlags(
      snapshotInfo,
      RowExpandActions.UPDATE,
      updatedRowExpandedData,
    );
  }
};

export const getCurrentSnapshotDetails = (
  rowIndex: number,
  snapshotInfo: SnapshotInformation,
) => {
  return (
    ({
      transactionNumber: snapshotInfo?.transactionNumber?.[rowIndex],
      accountChange: snapshotInfo?.amountChange?.[rowIndex],
    } as CurrentSnapshotDetails) ?? null
  );
};

const ActionContentWrapper = () => (
  <ActionContent>
    <ClickableLink href="#" data-on-click-role="downloadDocument">
      Download
    </ClickableLink>
    <ClickableLink href="#" data-on-click-role="openBreakdown">
      Breakdown
    </ClickableLink>
  </ActionContent>
);

const actionWrapper = (
  displayInvoiceItemsContent: boolean,
  isResponsive: boolean,
) =>
  displayInvoiceItemsContent && isResponsive
    ? ActionContentWrapper
    : downloadIcon;

const SnapshotTable = ({
  awsAppSyncClient,
  snapshotInfo,
  accountNumber,
  policyNumber,
  loading,
  error,
  onDocumentClick,
  displayInvoiceItemsContent,
  rowExpanded,
  setOrUpdateIsRowExpandedFlags,
}: SnapshotTableProps) => {
  const isResponsive = useIsResponsive();
  const [
    invoiceBreakdownModal,
    setShowInvoiceBreakdownModal,
  ] = useState<boolean>(false);
  const [selectedSnapshotIndex, setSelectedSnapshotIndex] = useState<number>(0);

  if (loading) {
    return (
      <div>
        <SAText type="standard" text="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <SAText type="standard" text="Error" />
      </div>
    );
  }

  const onRowClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedRowIndex: number,
  ) => {
    snapshotTableOnRowClick(
      event,
      onDocumentClick,
      policyNumber,
      snapshotInfo,
      selectedRowIndex,
      rowExpanded,
      setOrUpdateIsRowExpandedFlags,
      isResponsive,
      setShowInvoiceBreakdownModal,
      setSelectedSnapshotIndex,
    );
  };

  if (snapshotInfo?.policyKey && snapshotInfo?.policyKey.length > 0) {
    const policyChange = [];
    const TableHeaders: SATableColumn[] = [
      {
        align: 'left',
        name: 'Description',
      },
      {
        align: 'center',
        name: 'Effective Date',
      },
      {
        align: 'right',
        name: `${isResponsive ? 'Premium Change' : 'Prem. Change'}`,
        process: processColumn3,
      },
      {
        align: 'right',
        name: 'Total',
        process: processColumn4,
      },
      {
        align: 'center',
        name: `${isResponsive ? 'Action' : ''}`,
        process: actionWrapper(displayInvoiceItemsContent, isResponsive),
      },
    ];

    if (displayInvoiceItemsContent && !isResponsive) {
      TableHeaders.push({
        align: 'center',
        name: '',
        process: chevronDownIcon,
      });
    }

    for (let i = 0; i < snapshotInfo?.policyKey.length; i += 1) {
      const emptyArray = [];
      emptyArray.push(
        snapshotInfo.description[i] === null
          ? '-'
          : formatTitleCase(snapshotInfo.description[i]),
      );
      emptyArray.push(
        snapshotInfo.effectiveDate[i] === null
          ? '-'
          : formatDate(snapshotInfo.effectiveDate[i]),
      );

      if (snapshotInfo.description[i] === 'Renewal') {
        emptyArray.push('-');
      } else {
        emptyArray.push(
          snapshotInfo.amountChange[i] === null
            ? '-'
            : snapshotInfo.amountChange[i],
        );
      }

      emptyArray.push(
        snapshotInfo.amountTotal[i] === null
          ? '-'
          : snapshotInfo.amountTotal[i],
      );
      policyChange.push(emptyArray);
    }

    return (
      <SnapshotTableWrapper className="snapshot-table-component">
        <SATableComponent
          columns={TableHeaders}
          data={policyChange}
          variant="table-to-listview"
          onRowClick={onRowClick}
          accordionExpanded={rowExpanded}
          accordionContent={({ rowIndex }: RowDataProps) => {
            return (
              <>
                {displayInvoiceItemsContent && !isResponsive ? (
                  <AccordionContentWrapper>
                    <SnapshotInvoiceItemsContent
                      currentSnapshotDetails={getCurrentSnapshotDetails(
                        rowIndex,
                        snapshotInfo,
                      )}
                      awsAppSyncClient={awsAppSyncClient}
                      policyNumber={policyNumber}
                      accountNumber={accountNumber}
                      invoiceBreakdownModal={invoiceBreakdownModal}
                      closeModal={() => {}}
                    />
                  </AccordionContentWrapper>
                ) : null}
              </>
            );
          }}
        />
        {invoiceBreakdownModal && isResponsive && (
          <SnapshotInvoiceItemsContent
            awsAppSyncClient={awsAppSyncClient}
            policyNumber={policyNumber}
            accountNumber={accountNumber}
            currentSnapshotDetails={getCurrentSnapshotDetails(
              selectedSnapshotIndex,
              snapshotInfo,
            )}
            invoiceBreakdownModal={invoiceBreakdownModal}
            closeModal={() => {
              setShowInvoiceBreakdownModal(false);
              document.body.style.overflowY = 'auto';
            }}
          />
        )}
      </SnapshotTableWrapper>
    );
  }
  return (
    <div>
      <SAText type="standard" text="This policy has no changes" />
    </div>
  );
};

SnapshotTable.defaultProps = {
  snapshotInfo: {},
  loading: false,
  error: undefined,
  accountNumber: '0',
  policyNumber: '0',
  awsAppSyncClient: {},
};

export default SnapshotTable;
