import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../../util/global.theme';

const breakPoint = customBase?.global?.breakpoints?.medium;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `      
      width: 80%;
      top: 50%;
      left: 50%;      
      background-color: ${theme?.color?.primary?.foreground};
      border-radius: ${theme?.size?.radius?.small};
      text-align: center;
      position: absolute;      
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      @media screen and (min-width: ${breakPoint}px) {
        height: 400px;
        width: 600px;
        padding-top: 0;
      }
      @media screen and (max-width: ${breakPoint}px) {
        width: 100vw;
        min-width: 100%;    
        height: 100vh;
        min-height: 100%;
        padding-top: 8px;
      }
      `;
  }}
`;

export const ModalContents = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 20px 20px 25px;
    @media screen and (max-width: ${breakPoint}px) {
      width: 100%;      
      overflow: auto;
      padding: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.medium} ${theme?.size?.spacer?.medium} 20px;
    }
 `};
`;

export const ModalHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// font-size for title  best at 23px
export const ModalTitle = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-size: ${theme?.font?.primary?.size?.medium};
    font-weight: ${theme?.font?.primary?.weight?.bold};
    @media screen and (max-width: ${breakPoint}px) {
      transform: scale(1.05);
      margin-left: 2%;
    }
  `};
`;

export const ModalCloseWrap = styled.div`
  cursor: pointer;
  @media screen and (max-width: ${breakPoint}px) {
    transform: scale(0.9);
  }
`;

export const ModalBody = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.xs} ${theme?.size?.spacer?.medium} 0;
    @media screen and (max-width: ${breakPoint}px) {
      flex: 1 0 80%;
        margin: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.medium} ${theme?.size?.spacer?.medium} 0;
      }
`}
`;

export const DisclaimerSection = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: inline-flex;
    margin: ${theme?.size?.spacer?.xs} 0;
    padding: 15px ${theme?.size?.spacer?.small};
    background: #e7f5fa;
    border: 1px solid #b9e2f0;
    box-sizing: border-box;
    border-radius: 6px;
    border-left: thick solid #0173c6;
    align-items: center;
`};
`;
export const AlertIconHolder = styled.div`
  & > svg {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    flex: 1 0 1%;
  }
`;
export const DisclaimerNote = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    text-align: left;
    margin-left: ${theme?.size?.spacer?.small};
    color: #0C5066;
    word-wrap: break-word;
    white-space: break-spaces;
    font-size: ${theme?.font?.primary?.size?.medium};
    @media screen and (max-width: ${breakPoint}px) {
      transform: scale(1.04);
      margin-left: 12px;
      flex: 1 0 90%;      
    }
`};
`;
export const ModalFooter = styled.div`
  align-self: flex-end;
  @media screen and (max-width: ${breakPoint}px) {
    align-self: center;
    flex: 1 0 5%;
  }
`;
export const CloseButton = styled.div`
  @media screen and (max-width: ${breakPoint}px) {
    & > button {
      width: 50vw;
    }
  }
`;

export const AgencyDetails = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: ${theme?.size?.spacer?.small} 0;
 `};
`;
export const AgencyNameHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: ${theme?.size?.spacer?.small} 0 ${theme?.size?.spacer?.medium} 0;
  `}
`;

export const PhoneHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const IconSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: ${theme?.size?.spacer?.small};
 `}
`;
export const ClickableLink = styled.a`
  text-decoration: none;
  @media screen and (max-width: ${breakPoint}px) {
    & > p {
      transform: scale(1.04);
    }
  }
`;

export const EmailHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme?.size?.spacer?.small};
    word-wrap: break-word;
  `}
`;
export const MailAdjust = styled.div`
  margin-top: 8px;
`;
export const ErrorContent = styled.div``;
export const LoadingContent = styled.div``;
